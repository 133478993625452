import * as logger from 'loglevel';
import store from 'store';
import Masonry from 'masonry-layout';
import Cookies from 'js-cookie';
import Symbol from 'symbol';
import UrlChangeEmitter from 'on-url-change';

import * as utils from './utils';
import * as templates from './template';
import * as services from './services';
import * as goto from './goto';
import env from './env';
import {
  getTranslation
} from './i18n';
import ConfigMgr from './ConfigManager';
import {
  injectWenlongCompatible
} from './WenlongCompatible';
import FontFaceObserver from 'fontfaceobserver';
import jsonp from 'jsonp-promise';
import { isString } from 'util';
import { uaMatch } from './uaMatch';
import {
  showAccountBalance,
  loadMenuFontSize,
  overviewUrl,
  showMessage,
  openRealName,
  subUserLoginUrl,
  mainLoginUrl,
  isPoweredByApp,
  isPrivate,
  supportBuryingPoints,
  yunjianOverview,
  showWarn,
  showToolkit,
} from './config';

let _userPin;
let _userNick;
let _adminPin;
let _companyName = '';
let _role = null,
  _role_history = null; //角色信息
let ssoCount = 0;
let adminRoleCount = 0;
let _naviObj; //导航公共对象
let _subTitle = ''; //二级菜单标题
let _subMenuObj = null; //二级菜单json
let _curr = null; //当前选中菜单对象
const _callbackObj = {}; //回调函数对象
let _userConfig = {}; //用户信息
let _currPageSel = ''; //当前选中页面标识
const _urlChangeEmitter = UrlChangeEmitter();
let isMainMenuToggling = false;
let _msgId = 0;
let _autoShowSubMenu = true;
let _topMenuAdded = false,
  _sideMenuAdded = false,
  _ucMenuAdded = false;
let _sideMenuKeyObj = {};
let _ucSideMenuKeyObj = {};
let _isChangeName = false;
let _favMenu = [];

let _defalutFavMenu = ["containers","container","vpc","disk","oss","databases","elasticSearch","cloudcache"];
let _favMenuPages = [..._defalutFavMenu];
let _dropMenuHandle = null
const _languageId = Cookies.get('jdcloud_sitelang') || 'cn';
let _isDeleteMenu = false
let statusMap = {
  'UNVERIFIED':{content:'未实名',style:'errorVerify'},
  'COMPANY_VERIFIED':{content:'企业实名',style:'successVerify'},
  'PERSON_VERIFIED':{content:'个人实名',style:'successVerify'},
  'COMPANY_AUDIT':{content:'企业实名审核中',style:'warnVerify'},
  'PERSON_AUDIT':{content:'个人实名审核中',style:'warnVerify'}
}
let USER_STATUS_MPA = {
  UNVERIFIED:'UNVERIFIED',
  COMPANY_VERIFIED:'COMPANY_VERIFIED',
  PERSON_VERIFIED:'PERSON_VERIFIED',
  COMPANY_AUDIT:'COMPANY_AUDIT',
  PERSON_AUDIT:'PERSON_AUDIT'}
let USER_STATUS = { UNVERIFIED: 0, AUDIT: 1, VERIFIED: 2 }
let _name;
let _accountName;
let _accountId;
let _accountType;
let _roleAuth;
let _roleName;
let _admintRoleAuth;
let _roleType;
let _roleAccountName;

// 主菜单和子菜单的状态
// (隐藏、折叠、展开)x(主菜单、子菜单)
const ToggleStatus = Object.freeze({
  MainHide$SubHide_status1: Symbol('MainHide$SubHide_status1'), // 主菜单隐藏，子菜单隐藏
  MainSpread$SubHide_status2: Symbol('MainSpread$SubHide_status2'), // 主菜单折叠，子菜单展开
  MainCollapse$SubSpread_status3: Symbol('MainCollapse$SubSpread_status3'), // 主菜单折叠，子菜单收起
  MainCollapse$SubHide_status4: Symbol('MainCollapse$SubHide_status4') // 主菜单展开，子菜单隐藏
});

if (loadMenuFontSize) {
  let font = new FontFaceObserver('menuiconfonts');
  font.load().then(() => {
    console.log('字体加载成功');
  }, () => {
    console.log('字体加载失败');
    let _addLocal = setInterval(() => {
      if ($(document.body).length > 0) {
        clearInterval(_addLocal);
        $(document.body).addClass('localFont');
      }
    }, 100);
  });
}

let currentToggleStatus = ToggleStatus.MainSpread$SubHide_status2;

function isMainMenuCollapse() {
  return (
    currentToggleStatus === ToggleStatus.MainCollapse$SubHide_status2 ||
    currentToggleStatus === ToggleStatus.MainCollapse$SubSpread_status3
  );
}

/**
 * 检测当前页面是不是允许子账户访问的页面，不允许则跳错误页
 */
export async function checkSubAccountPermission() {
  if (_adminPin && _adminPin !== 'null') {
    if (!utils.isSubAccountUrl(location.href)) {
      goto.gotoNotSupportSubAccount();
    }
  }
}

export function checkHideSideMenu(callback) {
  if (
    window.hideSideMenu === true ||
    location.href.indexOf('/create') > 0 ||
    location.href.indexOf('hideSideMenu') > 0 ||
    location.href.indexOf('accountErr') > 0 ||
    location.href.indexOf('/commonPay') > 0
  ) {
    callback(true);
    return;
  }
  callback(false);
}

/**
 * Ynavi启动
 */
export async function bootstrap() {
  const config = ConfigMgr.get('config');
  _favMenu = config.favMenu;

  let _pin = decodeURIComponent(Cookies.get('pin'))
  _pin = !utils.isNull(_pin) ? _pin : null
  let _pt_pin = decodeURIComponent(Cookies.get('pwdt_id'))
  _pt_pin = utils.isNull(_pt_pin) ? null : _pt_pin
  _userPin = _pt_pin || _pin
  _userNick = decodeURIComponent(Cookies.get('unick'));
  _userNick = !utils.isNull(_userNick) ? _userNick : null;
  _userNick = _pt_pin || _userNick

  // 当DOM准备好了，就调用bootstrapImpl
  var _initHandle;
  clearInterval(_initHandle);
  _initHandle = setInterval(() => {
    if (window.Ynavi) clearInterval(_initHandle);
    if (
      ($('header').length > 0 && $('.mainMenu').length > 0) ||
      $('#header').length > 0
    ) {
      clearInterval(_initHandle);
      //if($('header').length > 0 && $('.mainMenu').length > 0){
      const ynavi = bootstrapImpl(config);
      //}
      const execs = [import( /* webpackChunkName: "exec-message" */ './exec/message.js'), import(/* webpackChunkName: "toolkit" */'./Toolkit.js')]
      if (supportBuryingPoints) {
        execs.concat([
          import( /* webpackChunkName: "exec-s" */ './exec/s.exec'),
          import( /* webpackChunkName: "exec-baidu" */ './exec/baidu.exec'),
          import( /* webpackChunkName: "exec-qidian" */ './exec/qidian.exec'),
          import( /* webpackChunkName: "exec-sgm" */ './exec/sgm.exec')
        ])
      }
      if (!isPrivate) {
        execs.concat([
          utils.loadScript(
            '//static2.jdcloud.com/subaccount-monitor/subaccount-monitor.js'
          )
        ])
      }
      //执行一次exec脚本
      Promise.all(execs).then(() => {
        logger.debug('奇点、子午线和百度埋点载入完成');
      });
    }
  }, 100);
}

function bootstrapImpl(config) {
  if (location.pathname === '' || location.pathname === '/') {
    // Cookies.set('currPage', '', { domain: '.jdcloud.com' });
    // Cookies.set('currPage', '', { domain: '.jcloud.com' });
    utils.setCookie('currPage', '')
  }

  const userName = _userNick || _userPin;

  // 初始化Ynavi
  const header = $('header').eq(0);
  header.addClass('YnaviHead');
  header.attr('id', 'YnaviHead');
  const ynavi = (window.Ynavi = new Ynavi(config));

  // 展开侧边栏的云主机
  $('.menu1')
    .eq(0)
    .click();

  checkHideSideMenu(isHideSideMenu => {
    if (isHideSideMenu) {
      currentToggleStatus = ToggleStatus.MainHide$SubHide_status1;
      $('.YnaviContent').attr('class', 'YnaviContent status1');
    } else {
      // 初始状态status3
      currentToggleStatus = ToggleStatus.MainSpread$SubHide_status3;
      $('.YnaviContent').attr('class', 'YnaviContent status3');
      $('.mainMenu, .subMenu, .mainCon').addClass('trans');
    }
  });

  // 在主内容区的class增加版本号 issue#1
  // http://git.jd.com/FE/jdcloud-console-navi/issues/1
  $('.mainCon').addClass('ynavi2');

  function pageTitleSet() {
    if (isPrivate) {
      document.title = window.bizServerConfig.title
      return
    }
    const isYjOnline = /^(yj-console)(-stag)?(.jdcloud.com)$/
    if (isYjOnline.test(window.location.host)) return
    if(Cookies.get('jdcloud_sitelang') == 'en'){
      document.title =
        document.title && document.title.includes('JD Cloud')
          ? document.title.replace('JD Cloud & AI', 'JD Cloud')
          : 'JD Cloud Console';
    }
    else{
      document.title =
        document.title && (document.title.includes('京东智联云') || document.title.includes('京东云'))
          ? document.title.replace('京东智联云', '京东云')
          : '京东云控制台';
    }
  }

  // 选中
  //ynavi.setCurrPage();
  // 其实_urlChangeEmitter内部调用的就是setInterval，看来polling在所难免
  function urlChangeEvt(url) {
    logger.debug('URL changed to: ', url);
    if (_currPageSel) _subMenuObj = _naviObj.getSubMenu(_currPageSel);
    if ($('header').length > 0) {
      if (!_topMenuAdded) {
        $('header').addClass('YnaviHead');
        ynavi.fillTopMenu(config);
        ynavi.fillDropMenu(config);
        ynavi.fetchApi();
        ynavi.setUserName(userName);
      }
    } else {
      _topMenuAdded = false;
    }
    if ($('.mainMenu').length > 0) {
      if (!_sideMenuAdded) ynavi.fillSideMenu(config);
    } else {
      _sideMenuAdded = false;
    }

    if (_adminPin || (_role && _role.roleName)) {
      $('header .accStatus #subUserCenter').html(
        templates.ChildAccount({
          name: _name,
          accountName: _accountName,
          accountId: _accountId,
          accountType: _accountType,
          roleAuth: _roleAuth,
          roleName:_roleName,
          admintRoleAuth: _admintRoleAuth,
          roleAccountName: _roleAccountName,
          roleType: _roleType,
          getTranslation: getTranslation
        })
      );
      $('header .account, header .company').hide();
      $('header .accStatus').show();
      if (_role && _role.roleName) {
        $('header .accStatus .linkBtn').hide();
      }
    }
    if (env.isUc) {
      ynavi.fillUcMenu();
    }
    if (!env.isUc) {
      ynavi.setCurrPage();
      return;
    }
  }
  _urlChangeEmitter.on('change', url => {
    setTimeout(() => {
      pageTitleSet();
    }, 200);
    urlChangeEvt(url);
  });
  let _hideSideMenu = window.hideSideMenu;
  Object.defineProperty(window, 'hideSideMenu', {
    get: () => {
      return _hideSideMenu;
    },
    set: newValue => {
      _hideSideMenu = newValue;
      urlChangeEvt(location.href);
    },
    enumerable: true,
    configurable: true
  });
  if (env.isUc) {
    if (!_ucMenuAdded && $('.subMenu').length > 0) {
      ynavi.fillUcMenu();
    } else {
      _ucMenuAdded = false;
    }
  } else {
    ynavi.setCurrPage();
  }

  // 引入文龙菜单兼容层
  injectWenlongCompatible();

  // 如果是子账号登录，需要做一些意义不明的操作
  if (_adminPin || (_role && _role.roleName)) {
    $('header .accStatus dl').html(
      templates.ChildAccount({
        pin: _userPin,
        childNick: _adminPin,
        parentNick: _companyName,
        role: _role,
        role_history: _role_history,
        getTranslation: getTranslation
      })
    );
    $('header .account, header .company').hide();
    $('header .accStatus').show();
    if (_role && _role.roleName) {
      $('header .accStatus .linkBtn').hide();
    }
  }

  pageTitleSet();
  $(window).ready(()=>{
    setTimeout(pageTitleSet, 400)
  })

  function addToolkit() {
    // 添加工具栏节点
    const parentDOM = $('.YnaviContent')[0]
    const toolkitDOM = document.createElement("div");
    toolkitDOM.className='toolkit-container'
    parentDOM.insertBefore(toolkitDOM, parentDOM.firstChild)
    const content = $('.toolkit-container')
    $(content).html(
      templates.ToolKit()
    )
  }
  if (showToolkit) {
    addToolkit()
  }



  return ynavi;
}
// 暴露给主应用，子应用挂在前，菜单加载前调用
window.renderSideMenu = function (event = {}) {
  if (!isPrivate)  return;
  const {app: appOptions} = event
  if (!Object.keys(appOptions).length) {
    return
  }
  const { props: { app = ''} } = appOptions
  const isUcApp = app === 'uc'
  if (isUcApp) {
    env.isUc = true
    const _Ynavi = window.Ynavi
    _Ynavi && _Ynavi.fillUcMenu()
    console.log(_Ynavi);
  } else {
    env.isUc = false
  }
}

export class Ynavi {
  constructor(config) {
    const page = location.hash.substr(1);
    this.currPage = page || '';
    if (!config.menuConfig) config.menuConfig = [];

    this.topMenu = config.navBarConfig;
    this.sideMenu = config.menuConfig;
    this.ucMenu = config.ucSideMenuObj;
    // this.sideMenuInit(this.sideMenu)
    this.sideMenu.forEach((_menu, _index) => {

      this.setMenuKeyValue({page:_menu.page,url: _menu.url, path:_menu.language, pages:_menu.page, name:_menu.name, icon:_menu.icon, tab:_menu.newTab, crossRecommendation: _menu.crossRecommendation});
      if (_menu.childMenu && _menu.childMenu.length > 0) {
        //level 为1
        _menu.childMenu.forEach((_menu1, _index1) => {
          _index1 === 0 ?
            this.setMenuKeyValue({
              page: _menu.page,
              url: _menu1.url,
              path: _menu1.language,
              pages:_menu1.page,
              tab:_menu1.newTab,
              crossRecommendation:_menu1.crossRecommendation,
            }) :
            this.setMenuKeyValue({page:_menu.page, pages:_menu1.page});
            //level1 menu
          this.setMenuKeyValue(
            {
              page:_menu1.page,
              url:_menu1.url,
              path: _menu1.language,
              pages: _menu1.page,
              name:_menu1.name,
              icon:_menu1.icon,
              tab: _menu1.newTab,
              crossRecommendation: _menu1.crossRecommendation
            }
          );
          if (_menu1.childMenu && _menu1.childMenu.length > 0) {
            //level 为2
            _menu1.childMenu.forEach((_menu2, _index2) => {
              _index1 === 0 && _index2 === 0 ?
                this.setMenuKeyValue(
                  {
                    page:_menu.page,
                    url:_menu2.url,
                    path: _menu2.language,
                    pages: _menu2.page,
                    tab: _menu2.newTab,
                    crossRecommendation: _menu2.crossRecommendation
                  }
                ) :
                this.setMenuKeyValue({page:_menu.page, pages: _menu2.page,});
              _index2 === 0 ?
                this.setMenuKeyValue(
                  {
                    page:_menu1.page,
                    url:_menu2.url,
                    path:  _menu2.language,
                    pages: _menu2.page,
                    tab:_menu2.newTab,
                    crossRecommendation:_menu2.crossRecommendation
                  }
                ) :
                this.setMenuKeyValue({page:_menu1.page,pages:_menu2.page});

              this.setMenuKeyValue(
                {
                  page:_menu2.page,
                  url:_menu2.url,
                  path:  _menu2.language,
                  pages: _menu2.page,
                  name:_menu2.name,
                  tab: _menu2.newTab,
                  crossRecommendation: _menu2.crossRecommendation
                }
              );
              if (_menu2.childMenu && _menu2.childMenu.length > 0) {
                _menu2.childMenu.forEach((_menu3, _index3) => {
                  _index1 === 0 && _index2 === 0 && _index3 === 0 ?
                    this.setMenuKeyValue(
                      {
                        page:_menu.page,
                        url:_menu3.url,
                        path:   _menu3.language,
                        pages:  _menu3.page,
                        tab:  _menu3.newTab,
                        crossRecommendation:  _menu3.crossRecommendation
                      }
                    ) :
                    this.setMenuKeyValue( {page:_menu.page,pages:_menu3.page});
                  _index2 === 0 && _index3 === 0 ?
                    this.setMenuKeyValue(
                      {
                        page:_menu1.page,
                        url:_menu3.url,
                        path: _menu3.language,
                        pages: _menu3.page,
                        tab: _menu3.newTab,
                        crossRecommendation: _menu3.crossRecommendation
                      }
                    ) :
                    this.setMenuKeyValue({page:_menu1.page,pages:_menu3.page});
                  _index3 === 0 ?
                    this.setMenuKeyValue(
                      {
                        page:_menu2.page,
                        url:_menu3.url,
                        path: _menu3.language,
                        pages: _menu3.page,
                        crossRecommendation: _menu3.crossRecommendation,
                        tab: _menu3.newTab
                      }
                    ) :
                    this.setMenuKeyValue({page:_menu2.page,pages:_menu3.page});
                  this.setMenuKeyValue(
                    {
                      page:_menu3.page,
                      url:_menu3.url,
                      path: _menu3.language,
                      pages: _menu3.page,
                      name:_menu3.name,
                      crossRecommendation:_menu3.crossRecommendation,
                      tab: _menu3.newTab

                    }
                  );
                  if (_menu3.childMenu && _menu3.childMenu.length > 0) {
                    _menu3.childMenu.forEach((_menu4, _index4) => {
                      _index1 === 0 && _index2 === 0 && _index3 === 0 && _index4 === 0?
                        this.setMenuKeyValue(
                          {
                            page:_menu.page,
                            url:_menu4.url,
                            path:   _menu4.language,
                            pages:  _menu4.page,
                            tab:  _menu4.newTab,
                            crossRecommendation:  _menu4.crossRecommendation
                          }
                        ) :
                        this.setMenuKeyValue( {page:_menu.page,pages:_menu4.page});
                      _index2 === 0 && _index3 === 0 && _index4 === 0 ?
                        this.setMenuKeyValue(
                          {
                            page:_menu1.page,
                            url:_menu4.url,
                            path: _menu4.language,
                            pages: _menu4.page,
                            tab: _menu4.newTab,
                            crossRecommendation: _menu4.crossRecommendation
                          }
                        ) :
                        this.setMenuKeyValue({page:_menu1.page,pages:_menu4.page});
                      _index3 === 0 && _index4 === 0 ?
                        this.setMenuKeyValue(
                          {
                            page:_menu2.page,
                            url:_menu4.url,
                            path: _menu4.language,
                            pages: _menu4.page,
                            crossRecommendation: _menu4.crossRecommendation,
                            tab: _menu4.newTab
                          }
                        ) :
                        this.setMenuKeyValue({page:_menu2.page,pages:_menu4.page});
                      _index4 === 0 ?
                        this.setMenuKeyValue(
                          {
                            page:_menu3.page,
                            url:_menu4.url,
                            path: _menu4.language,
                            pages: _menu4.page,
                            crossRecommendation: _menu4.crossRecommendation,
                            tab: _menu4.newTab
                          }
                        ) :
                        this.setMenuKeyValue({page:_menu3.page,pages:_menu4.page});
                      this.setMenuKeyValue(
                        {
                          page:_menu4.page,
                          url:_menu4.url,
                          path: _menu4.language,
                          pages: _menu4.page,
                          name:_menu4.name,
                          crossRecommendation:_menu4.crossRecommendation,
                          tab: _menu4.newTab

                        }
                      );
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    this.sideMenu.forEach(_menu => {
      if (_sideMenuKeyObj[_menu.page])
        _menu.allpages = _sideMenuKeyObj[_menu.page].pages;
      if (_menu.childMenu && _menu.childMenu.length > 0) {
        _menu.childMenu.forEach(_menu1 => {
          if (_sideMenuKeyObj[_menu1.page])
            _menu1.allpages = _sideMenuKeyObj[_menu1.page].pages;
          if (_menu1.childMenu && _menu1.childMenu.length > 0) {
            _menu1.childMenu.forEach(_menu2 => {
              if (_sideMenuKeyObj[_menu2.page])
                _menu2.allpages = _sideMenuKeyObj[_menu2.page].pages;
              if (_menu2.childMenu && _menu2.childMenu.length > 0) {
                _menu2.childMenu.forEach(_menu3 => {
                  if (_sideMenuKeyObj[_menu3.page])
                    _menu3.allpages = _sideMenuKeyObj[_menu3.page].pages;
                  if (_menu3.childMenu && _menu3.childMenu.length > 0) {
                    _menu3.childMenu.forEach(_menu4 => {
                      if (_sideMenuKeyObj[_menu4.page])
                        _menu4.allpages = _sideMenuKeyObj[_menu4.page].pages;
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    if (this.ucMenu) {
      this.ucMenu.forEach((_menu, _index) => {
        _menu.path = _menu.language || _menu.path
        this.setUCMenuKeyValue(_menu.page, _menu.url, _menu.path, _menu.page);
        if (_menu.childMenu && _menu.childMenu.length > 0) {
          _menu.childMenu.forEach((_menu1, _index1) => {
            _menu1.path = _menu1.language || _menu1.path
            _index1 === 0 ?
              this.setUCMenuKeyValue(
                _menu.page,
                _menu1.url,
                _menu1.path,
                _menu1.page
              ) :
              this.setUCMenuKeyValue(_menu.page, '', '', _menu1.page);
            this.setUCMenuKeyValue(
              _menu1.page,
              _menu1.url,
              _menu1.path,
              _menu1.page
            );
            if (_menu1.childMenu && _menu1.childMenu.length > 0) {
              _menu1.childMenu.forEach((_menu2, _index2) => {
                _menu2.path = _menu2.language || _menu2.path
                _index1 === 0 && _index2 === 0 ?
                  this.setUCMenuKeyValue(
                    _menu.page,
                    _menu2.url,
                    _menu2.path,
                    _menu2.page
                  ) :
                  this.setUCMenuKeyValue(_menu.page, '', '', _menu2.page);
                _index2 === 0 ?
                  this.setUCMenuKeyValue(
                    _menu1.page,
                    _menu2.url,
                    _menu2.path,
                    _menu2.page
                  ) :
                  this.setUCMenuKeyValue(_menu1.page, '', '', _menu2.page);
                this.setUCMenuKeyValue(
                  _menu2.page,
                  _menu2.url,
                  _menu2.path,
                  _menu2.page
                );
              });
            }
          });
        }
      });
      this.ucMenu.forEach(_menu => {
        if (_ucSideMenuKeyObj[_menu.page])
          _menu.allpages = _ucSideMenuKeyObj[_menu.page].pages;
      });
    }
    // console.log(this.sideMenu)
    // console.log(this.ucMenu)
    // console.log(_sideMenuKeyObj)
    // console.log(_ucSideMenuKeyObj)
    // 选中某个菜单项。setCurrPage是重点保护API。千万不能改名。
    // 各个业务线通过 window.Ynavi.setCurrPage('page') 使用
    this.setCurrPage = this.setCurrPage.bind(this);
    this.setUserName = this.setUserName.bind(this);
    this.setAccountInfo = this.setAccountInfo.bind(this);
    this.setUserStatus = this.setUserStatus.bind(this);
    this.setChildAccount = this.setChildAccount.bind(this);
    this.setChildAccSel = this.setChildAccSel.bind(this);
    this.hideLink = this.hideLink.bind(this);
    this.topMenuAdd = this.topMenuAdd.bind(this);
    this.fillUcMenu = this.fillUcMenu.bind(this);
    this.fillSideMenu = this.fillSideMenu.bind(this);
    this.setMainMenuStyle = this.setMainMenuStyle.bind(this);
    this.toggleSubMenu_n = this.toggleSubMenu_n.bind(this);
    //this.toggleMainMenu = this.toggleMainMenu.bind(this);
    this.sideMenuAdd = this.sideMenuAdd.bind(this);
    this.hideSubMenu = this.hideSubMenu.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.invalidateCache = this.invalidateCache.bind(this);
    this.setMenuSelectAdvanced = this.setMenuSelectAdvanced.bind(this);
    this.fetchApi = this.fetchApi.bind(this);
    this.setAutoShowSubMenu = this.setAutoShowSubMenu.bind(this);
    this.setAddedMenuSelect = this.setAddedMenuSelect.bind(this);
    this.viewMessage = this.viewMessage.bind(this);
    this.getSearchMenu = this.getSearchMenu.bind(this);
    this.getFavMenu = this.getFavMenu.bind(this);
    this.getVisitedMenu = this.getVisitedMenu.bind(this);
    this.copyAccount = this.copyAccount.bind(this);
    this.copyAccountId = this.copyAccountId.bind(this);
    this.copyAdminAccountId = this.copyAdminAccountId.bind(this);

    _naviObj = this;
    this.initEvent();
    uaMatch()

    if ($('header').length > 0) {
      if (!_topMenuAdded) {
        this.fillTopMenu(config);
        this.fillDropMenu(config);
        this.fetchApi();
      }
    } else {
      _topMenuAdded = false;
    }
    // if ($('.mainMenu').length > 0) {
    //   if (!_sideMenuAdded) this.fillSideMenu(config);
    //   //if(!env.isUc)this.setCurrPage();
    // } else {
    //   _sideMenuAdded = false;
    // }
  }

  viewMessage(_title, _message, _callback){
    $('.Ynavi-message h3 span').text(_title)
    $('.Ynavi-message p').text(_message)
    $('.Ynavi-message').show()
    $('.Ynavi-message a').on('click',(_event)=>{
      _event = window.event || _event
      if (_event.stopPropagation) _event.stopPropagation();
      if (_event.cancelBubble) _event.cancelBubble = true;
      $('.Ynavi-message').hide()
      if (_callback) _callback()
      if (_event.returnValue) _event.returnValue = false
      return false
    })
  }
// 仅为sideMenu设置keyValue
  setMenuKeyValue({page, url, path, pages, name, icon, tab, crossRecommendation}) {
    //if(_sideMenuKeyObj[_page])console.log(`重复page值：${_page}，URL：${_url}`)
    if (!_sideMenuKeyObj[page]) _sideMenuKeyObj[page] = {};
    if (url) _sideMenuKeyObj[page].url = url;
    if (path) _sideMenuKeyObj[page].path = path;
    if (name) _sideMenuKeyObj[page].name = name;
    if (page) _sideMenuKeyObj[page].page = page;
    if (icon) _sideMenuKeyObj[page].icon = icon;
    if (crossRecommendation !== undefined) _sideMenuKeyObj[page].crossRecommendation = crossRecommendation;
    if (tab !== undefined) _sideMenuKeyObj[page].tab = tab;
    _sideMenuKeyObj[page].pages = _sideMenuKeyObj[page].pages ? _sideMenuKeyObj[page].pages + pages + '|' : `|${pages}|`;
  }

  setUCMenuKeyValue(_page, _url, _path, _pages) {
    //if(_sideMenuKeyObj[_page])console.log(`重复page值：${_page}，URL：${_url}`)
    if (!_ucSideMenuKeyObj[_page]) _ucSideMenuKeyObj[_page] = {};
    if (_url) _ucSideMenuKeyObj[_page].url = _url;
    if (_path) _ucSideMenuKeyObj[_page].path = _path;
    _ucSideMenuKeyObj[_page].pages = _ucSideMenuKeyObj[_page].pages ?
      _ucSideMenuKeyObj[_page].pages + _pages + '|' :
      `|${_pages}|`;
  }

  setAutoShowSubMenu(value) {
    _autoShowSubMenu = value;
  }

  setMessageCount(count) {
    if (isNaN(Number(count))) count = 0
    if(count <= 0){
      $("header a[data-page='contact'] .message").hide();
    }
    else{
      $("header a[data-page='contact'] .message").show();
    }
  }

  invalidateCache() {
    store.remove('YnaviConfig');
    store.remove('YnaviConfigLastModified');
    location.reload();
  }

  hideSubMenu() {
    logger.debug('隐藏子菜单');
    currentToggleStatus = ToggleStatus.SubHide_status3;
    $('.YnaviContent').attr('class', 'YnaviContent status3');
    $('.subMenu').removeClass('spread').addClass('none');
  }

  showSubMenu() {
    currentToggleStatus = ToggleStatus.SubHide_status2;
    $('.YnaviContent').attr('class', 'YnaviContent status2');
    $('.subMenu').addClass('spread').removeClass('none');
  }

  fetchApi() {
    const ignoreError = () => { };
    const promises = [];

    // 用户详情
    const getUserTypeP = services.describeUserPin().then(_data => {
      // https://cf.jd.com/pages/viewpage.action?pageId=1169492742
      // loginType 1-主账号；2-子账号；3-角色；0-无
      // name 统一展示名称 loginType为1时：主账号名 loginType为2时：子账号名 loginType为3时：子用户角色代入显示子用户名 服务商角色代入显示角色名
      // accountId 主账号id
      // accountName 主账号账号名
      // roleType loginType为3时返回  0-子用户角色代入 1-服务商角色代入
      // roleAccountName 代入的主账号账号名
      const { loginName, loginType, name, roleName, roleType, roleAccountName, roleAccountId, providerName, providerLogin, accountName, accountId, companyAuthStatus, personAuthStatus } = _data.result
      if (openRealName) {
        // 添加用户实名状态
        this.setUserStatus(companyAuthStatus, personAuthStatus)
      }
      // 子账户
      if (loginType === 2 || loginType === 3) {
        // 保留原先逻辑_role
        this.setRoleInfo()
        // 按用户信息展示
        const isSubUser = loginType === 2 //是否为子用户
        const isSubRole = loginType === 3 && roleType === 0 //是否为子用户角色代入
        _name = isSubUser ? loginName : roleName
        _accountName = isSubUser ? accountName : roleAccountName
        _accountId = isSubUser ? accountId : roleAccountId
        _accountType = isSubUser ? '子用户' : roleType === 0 ? '用户角色' : '联合身份'
        _roleAuth = isSubRole ? getTranslation('登录身份：') : 'IDP名称：'
        _roleName = isSubRole ? loginName : providerName
        _admintRoleAuth = isSubRole ? getTranslation('主账号：') : 'IDP账号：'
        _roleAccountName = isSubRole ? accountName : providerLogin
        _roleType = isSubRole ? getTranslation('子用户') : ''
        // 右上角展示
        $("header a[data-page='user']").attr('title',name);
        $("header a[data-page='user'] span").html(name);
        $("header .userCenter .userPin").html(name);
        // userCenter 子账号是loginName  	角色名是roleName
        $('header .accStatus #subUserCenter').html(
          templates.ChildAccount({
            name: _name,
            accountName: _accountName,
            accountId: _accountId,
            accountType: _accountType,
            roleAuth: _roleAuth,
            roleName:_roleName,
            admintRoleAuth: _admintRoleAuth,
            roleAccountName: _roleAccountName,
            roleType: _roleType,
            getTranslation: getTranslation
          })
        );
        $('header .account, header .company').hide();
        $('header .accStatus').show();
        if (isSubUser) {
          // 子账户的时候,显示切换角色功能
         $('header .accStatus .btn-role-login').show()
          $('header .accStatus .linkBtn').show();
        }
        // 角色带入显示角色信息
        if (!isSubUser) {
          $('header .accStatus .userRoleInfo').show();
        }
        // 子用户角色带入
        if (isSubRole) {
          $('header .accStatus .btn-role-logout').show()
        }
        } else {
          // 展示主账号名称
          this.setUserName(name)
          // 展示主账号id
          this.setUserId(accountId)
          // 展示主账号
          $("#userStatus .accountType span").html('主账号');
          $('.userCenter .userInfo').show()
        }
    }).catch(ignoreError);
    promises.push(getUserTypeP);

    // 收藏的菜单
    const getFavMenuP = services.getFavMenu().then(_data => {
      if (_data.code === 0) {
        _favMenuPages = _data.result ? (isString(_data.result) ? _data.result.split(',') : [..._data.result]) : [..._defalutFavMenu]
        this.fillFavMenu()
        this.searchMenu('')
      }
      else {
        _favMenuPages = [..._defalutFavMenu]
        this.fillFavMenu()
        this.searchMenu('')
      }
    }, _err => {
        _favMenuPages = [..._defalutFavMenu]
        this.fillFavMenu()
        this.searchMenu('')
    }).catch(ignoreError);
    promises.push(getFavMenuP);

    if (showMessage) {
      // 消息数
      const getTotalCountP = services
        .getTotalCount()
        .then(data => {
          if (data.code === 0) {
            this.setMessageCount(data.result);
          }
        })
        .catch(ignoreError);
      promises.push(getTotalCountP);
    }

    // 余额和欠款
    if (!_adminPin && !_role && showAccountBalance) {
      const balanceAndArrearsP = services
        .getBalanceAndArrears()
        .then(data => {
          if (data.code !== 0) {
            return;
          }
          const {
            unick,
            balance,
            arrears
          } = data;
          this.setAccountInfo(balance, arrears);
        })
        .catch(ignoreError);
      promises.push(balanceAndArrearsP);
    }

    // 消息
    if (showMessage) {
      const msgP = services
        .popMsg()
        .then(data => {
          if (data.code !== 0) {
            return;
          }
          if (!data.result.content) {
            return;
          }
          _msgId = data.result.id;
          $(document.body).append(templates.MessageLayerBox());
          $('.YnaviMsgLayer .YnaviMsgContent').html(
            templates.MessageLayerContent({
              _msg: data.result
            })
          );
          $('.YnaviMsgLayer').show(() => {
            $('.YnaviMsgLayer').addClass('view');
          });
        })
        .catch(ignoreError);
      promises.push(msgP);
    }

    if (showWarn) {
      // 警告
      const alertSymbol = Cookies.get(`${_userPin}alerted`);
      if (alertSymbol && +new Date() >= +alertSymbol) {
        const getNgDomainMsgP = services
          .getNgDomainMsg()
          .then(data => {
            if (data.code !== 0) {
              return;
            }
            const messages = [];
            for (let p of data.result) {
              const ips = {
                ip: p,
                domain: data.result[p]
              };
              messages.push(ips);
            }
            if (messages.length <= 0) {
              return;
            }
            const expires = +new Date() + 1000 * 60 * 60 * 8;
            utils.setCookie(`${_userPin}alerted`, expires);
            $('.YPopLayer .YPopCnt').html(
              templates.AlertLayerContent({
                _msg: messages
              })
            );
            $('.YPopLayer').show(() => {
              $('.YPopLayer').addClass('view');
            });
          })
          .catch(ignoreError);
        promises.push(getNgDomainMsgP);
      }
    }

    if (!isPrivate) {
      // 判断当前账户是否需要被拦截
      const getIsIntercept = services
        .accountIntercept()
        .then(data => {
          if (data.code === 1) {
            console.log('此账户需要被拦截')
            location.replace(`//uc.jdcloud.com/account/safety-settings/account-upgrade?returnUrl=${ encodeURIComponent(window.location.href) }`)
          }
          else {
            console.log('此账户不需要被拦截')
          }
        })
        .catch(ignoreError);
      promises.push(getIsIntercept);
    }

    return Promise.all(promises);
  }

  /**
   * 渲染顶栏
   */
  fillTopMenu(config) {
    // 插入顶栏
    const header = $('header').eq(0);
    config.navBarConfig.forEach((_menu1) => {
      if (_menu1.isAdminHide === undefined) _menu1.isAdminHide = 0
      if (_menu1.childMenu && _menu1.childMenu.length > 0) {
        _menu1.childMenu.forEach(_menu2 => {
          if (_menu2.isAdminHide === undefined) _menu2.isAdminHide = 0
        })
      }
    })
    $(header).html(
      templates.TopNavi({
        topMenu: config.navBarConfig,
        languageId: _languageId,
        adminPin: _adminPin,
        getTranslation: getTranslation,
        overviewPage: overviewUrl || '//console.jdcloud.com',
        bizServerConfig:window.bizServerConfig
      })
    );

    const _ul = $(header).find('ul');

    // 概览
    const isOverview =
      location.href.indexOf('console.jdcloud.com/overview') >= 0 ||
      location.href.indexOf('console-stag.jdcloud.com/overview') >= 0 ||
      location.href.indexOf('static2.jdcloud.com/overview') >= 0;

    $(_ul)
      .find("a[data-page='outline']")
      .parent()
      .addClass(isOverview ? 'left cur' : 'left');

    // 产品
    $(_ul)
      .find("a[data-page='product']")
      .parent()
      .parent()
      .addClass('left flow-wrap serviceTitle')
      .append('<div class="product"></div>');

    $(_ul)
      .find("a[data-page='partnerInfo']")
      .parent()
      .addClass('mr16')
      .append('<div class="partnerInfo"></div>');
    $(_ul)
      .find("a[data-page='lang']")
      .parent()
      .addClass('iconBox')
      .append('<div class="lang"></div>');
    $(_ul)
      .find("a[data-page='user']")
      .parent()
      .addClass('userBox')

    // 用户中心
    let userCenterHtml = '';
    try {
      userCenterHtml = templates.UserCenter({ ...config.userCenterConfig,getTranslation});
    } catch (ex) {
      logger.error(ex);
    }
    $(_ul)
      .find("a[data-page='user']")
      .parent()
      .append(userCenterHtml);

    _topMenuAdded = true;
  }

  fillSideMenu() {
    if (_sideMenuAdded) return;
    this.setMainMenuStyle({
      display: 'block'
    });
    $('.mainMenu .menuBox').css('margin-top', 0);
    $('.mainMenu').html(templates.MainMenuNew({
      mainMenu: this.sideMenu
    }));
    $('.mainMenu').attr('style', '');
    _sideMenuAdded = true;
  }

  ucMenuUrlMatch() {
    let {
      hostname,
      pathname
    } = location;
    // hostname = 'capital-stag.jdcloud.com'
    // pathname = 'cost/capital/remittance'
    let fhostname = this.filterUrlStag(hostname);
    pathname = pathname.charAt(0) === '/' ? pathname.substr(1) : pathname;
    let _pathname_c =
      pathname.lastIndexOf('-') > pathname.lastIndexOf('/') ?
        pathname.substring(0, pathname.lastIndexOf('-')) :
        null;
    let _pathname_s =
      pathname.lastIndexOf('/') > 0 ?
        pathname.substring(0, pathname.lastIndexOf('/')) :
        null;
    let _page = null;
    function canMatch(_menuObj) {
      let _menu = {
        _canMatch: true
      };
      if (!_menuObj) _menu._canMatch = false;
      if (_menuObj) {
        if (_menuObj.pages && _menuObj.pages.split('|').length > 3)
          _menu._canMatch = false;
        if (_menuObj.url && _menuObj.url.indexOf(`//${hostname}`) < 0 && _menuObj.url.indexOf(`//${fhostname}`) < 0)
          _menu._canMatch = false;
        _menu._url = _menuObj.url;
        _menu._path =
          _menuObj.path && _menuObj.path.charAt(0) === '/' ?
            _menuObj.path.substr(1) :
            _menuObj.path;
      } else {
        _menu._canMatch = false;
        _menu._url = null;
        _menu._path = null;
      }
      return _menu;
    }
    for (let _p in _ucSideMenuKeyObj) {
      let {
        _url,
        _path,
        _canMatch
      } = canMatch(_ucSideMenuKeyObj[_p]);
      if (!_canMatch) continue;
      if (
        (_url && (_url.indexOf(`//${hostname}/${pathname}`) >= 0 || _url.indexOf(`//${fhostname}/${pathname}`) >= 0)) || (_path && _path === pathname)
      ) {
        _page = _p;
        break;
      }
    }
    if (_page) return _page;
    if (_pathname_c) {
      for (let _p in _ucSideMenuKeyObj) {
        let {
          _url,
          _path,
          _canMatch
        } = canMatch(_ucSideMenuKeyObj[_p]);
        if (!_canMatch) continue;
        if (
          (_url && (_url.indexOf(`//${hostname}/${_pathname_c}`) >= 0 || _url.indexOf(`//${fhostname}/${_pathname_c}`) >= 0)) ||
          (_path && _path.indexOf(_pathname_c) >= 0)
        ) {
          _page = _p;
          break;
        }
      }
    }
    if (_page) return _page;
    if (_pathname_s) {
      for (let _p in _ucSideMenuKeyObj) {
        let {
          _url,
          _path,
          _canMatch
        } = canMatch(_ucSideMenuKeyObj[_p]);
        if (!_canMatch) continue;
        if (
          (_url && (_url.indexOf(`//${hostname}/${_pathname_s}`) >= 0 || _url.indexOf(`//${fhostname}/${_pathname_s}`) >= 0)) ||
          (_path && _path.indexOf(_pathname_s) >= 0)
        ) {
          _page = _p;
          break;
        }
      }
    }
    return _page || Cookies.get('ucCurrPage');
  }

  ucMenuSelect() {
    let _page = this.ucMenuUrlMatch();
    let _ucMenuSel = null;
    console.log('UC菜单路径匹配：' + _page);
    this.currPage = _page;
    if (_page) {
      // Cookies.set('ucCurrPage', _page, { domain: '.jdcloud.com' });
      utils.setCookie('ucCurrPage', _page)
      for (let i = 0, len = this.ucMenu.length; i < len; i++) {
        if (this.ucMenu[i].allpages.indexOf(`|${_page}|`) >= 0) {
          _ucMenuSel = this.ucMenu[i];
          break;
        }
      }
    }
    if (!_ucMenuSel) _ucMenuSel = this.ucMenu[0];
    return {
      _page,
      _ucMenuSel
    };
  }

  fillUcMenu() {
    // uc 二级菜单
    if (
      window.hideSideMenu === true ||
      location.href.indexOf('hideSideMenu') > 0
    ) {
      currentToggleStatus = ToggleStatus.MainHide$SubHide_status1;
      $('.YnaviContent').attr('class', 'YnaviContent status1');
      return;
    }
    $('.mainMenu, .subMenu, .mainCon').addClass('trans');
    currentToggleStatus = ToggleStatus.SubHide_status2;
    $('.YnaviContent').attr('class', 'YnaviContent status2');
    $('.subMenu').removeClass('none').addClass('spread');

    let {
      _page,
      _ucMenuSel
    } = this.ucMenuSelect();
    // 填充边栏子菜单
    if (_ucMenuSel) {
      if ($('.subMenu .subMenu--title').html() !== _ucMenuSel.name) {
        $('.subMenu').html(
          templates.UcSubMenu({
            subTitle: _ucMenuSel.name,
            subMenu: _ucMenuSel.childMenu,
            // currentPathname: pathname,
            currentPage: _page,
            getTranslation: getTranslation
          })
        );
      } else {
        $('.subMenu a').removeClass('cur');
        $(".subMenu a[data-page='" + _page + "']").addClass('cur');
        let _ul = $(".subMenu a[data-page='" + _page + "']").parents(
          '.subInMenu'
        );
        if (_ul.length > 0) {
          $(_ul).prev('a').addClass('cur')
          if (!_ul.parent('li').hasClass('open')) {
            $('.subMenu li.open').removeClass('open');
            _ul.parent('li').addClass('open');
          }
        }
      }
      // if ($('.subMenu .subMenu--title').length > 0) $('.subMenu .subMenuBox').css('top', ($('.subMenu .subMenu--title').get(0).offsetHeight + 1) + 'px')
      this.setSubMeMenuTop()
    }
  }

  setSubMeMenuTop () {
    setTimeout(()=>{
      let _top = $('.subMenu .subMenu--title').length > 0 ? $('.subMenu .subMenu--title').get(0).offsetHeight : 50
      if (_top <= 0) _top = 50
      if ($('.subMenu .subMenu--title').length > 0) $('.subMenu .subMenuBox').css('top', (_top + 1) + 'px')
    },100)
  }

  fillDropMenu(config) {
    const productElement = $('header .product');
    // 插入产品模板
    const dropMenuHtml = templates.DropMenu({
      menu: config.product ? config.product : config.menuConfig, // 官网产品数据 || 菜单数据
      languageId: _languageId,
      type: config.product ? 'product' : 'menu',
      favMenuKey: _favMenuPages,
      getTranslation: getTranslation
    });
    $('header .product').height((document.documentElement.clientHeight || document.body.offsetHeight) - 50)
    productElement.html(dropMenuHtml);
    let _boxWidth = $('.YnaviHead').width() - (_languageId === 'en' ? 340 : 280)
    $('header .productBox .menuSearchbox, header .productBox .dropMenuBox').css({
      width: (_languageId === 'en' ? 860 : 960) + 'px'
    });
    this.fillVisitedMenu()
    // 瀑布流布局初始化
    new Masonry(document.querySelector('header .productBox .dropMenuBox'), {
      itemSelector: '.pro-item',
      columnWidth: _languageId === 'en' ? 250 : 200,
      fitWidth: false,
      gutter: _languageId === 'en' ? 20 : 48
    });
  }

  getFavMenu(){
    if (!Array.isArray(_favMenuPages)) _favMenuPages = []
    let _newFavMenu = []
    let _newFavMenuPages = []
    _favMenuPages.forEach(_page=>{
      if (_sideMenuKeyObj[_page]){
        _newFavMenuPages.push(_page)
        _newFavMenu.push(_sideMenuKeyObj[_page])
      }
    })
    return {favMenu: _newFavMenu, favMenuPages: _newFavMenuPages}
  }

  fillFavMenu(){
    let _faveMenuObj = this.getFavMenu()
    _favMenu = [..._faveMenuObj.favMenu]
    _favMenuPages = [..._faveMenuObj.favMenuPages]
    $(".product .favmenu").html(templates.favMenuList({
      favMenu: _favMenu,
      favMenuPages: _favMenuPages,
      languageId: _languageId
    }))
    if ($(document.body).height() - 199 < _favMenuPages.length * 40){
      $(".product .favMenuBox").addClass('overflow-bottom')
    }
  }

  fillVisitedMenu(){
    let _visitedMenu = Cookies.get(_userPin + 'visitedMenu')
    let _visitedMenuObj = []
    if (_visitedMenu){
      let _visitedArr = _visitedMenu.split(',')
      _visitedArr.forEach(_page=>{
        if (_sideMenuKeyObj[_page])_visitedMenuObj.push(_sideMenuKeyObj[_page])
      })
      $('.product .visitedMenu').html(templates.visitedMenuList({
        visitedMenu: _visitedMenuObj,
        languageId: _languageId
      }))
    }
  }

  getVisitedMenu(){
    let _visitedMenu = Cookies.get(_userPin + 'visitedMenu')
    let _visitedMenuObj = []
    if (_visitedMenu) {
      let _visitedArr = _visitedMenu.split(',')
      _visitedArr.forEach(_page => {
        if (_sideMenuKeyObj[_page]) _visitedMenuObj.push({ name: _sideMenuKeyObj[_page].name, url: _sideMenuKeyObj[_page].url})
      })
    }
    return _visitedMenuObj
  }

  setMainMenuStyle(style) {
    if (!style) return;
    if ($('.mainMenu').length <= 0) {
      const _getMainconHandle = setInterval(function () {
        if ($('.mainMenu').length === 1) {
          clearInterval(_getMainconHandle);
          $('.mainMenu').css(style);
        }
      }, 100);
    } else {
      $('.mainMenu').css(style);
    }
  }

  setMainConStyle(_config) {
    if (window.hideSideMenu === true) {
      clearInterval(_getMainconHandle);
      $('.mainCon').css({
        left: 0
      });
      return;
    }

    if ($('.mainCon').length <= 0) {
      var _getMainconHandle = setInterval(function () {
        if (window.hideSideMenu === true) {
          clearInterval(_getMainconHandle);
          $('.mainCon').css({
            left: 0
          });
          return;
        }
        if ($('.mainCon').length === 1) {
          clearInterval(_getMainconHandle);
          $('.mainCon').css(_config);
          return;
        }
      }, 100);
    } else {
      $('.mainCon').css(_config);
    }
  }

  findMenuByPage(_page) {
    let _currMenusel = null;

    function setMenuValue(_name, _arr, _index) {
      if (_name == 5 && _currMenusel[`menu${_name}_arr`]) return;
      _currMenusel[`menu${_name}_idx`] = _index;
      _currMenusel[`menu${_name}_name`] =
        _arr && _arr.length > 0 ? _arr[_index].name : '';
      _currMenusel[`menu${_name}_page`] =
        _arr && _arr.length > 0 ? _arr[_index].page : '';
      _currMenusel[`menu${_name}_arr`] = _arr;
      _currMenusel[`menu${_name}_child`] =
        _arr && _arr.length > 0 && _arr[_index].childMenu && Array.isArray(_arr[_index].childMenu) ? _arr[_index].childMenu : null;
    }
    if (!this.sideMenu || this.sideMenu.length <= 0) {
      _currMenusel = {};
      return null;
    }
    this.sideMenu.forEach((_menu, _index) => {
      if (_menu.allpages.indexOf(`|${_page}|`) >= 0 || _menu.page === _page) {
        _currMenusel = {};
        _currMenusel.canFind = true;
        _currMenusel.levenIdx = _index;
        setMenuValue(1, this.sideMenu, _index);
      }
    });
    if (!_currMenusel) return null;
    for (var i = 1; i < 5; i++) {
      if (_currMenusel && _currMenusel[`menu${i}_child`]) {
        _currMenusel[`menu${i}_child`].forEach((_menu, _index) => {
          if (
            (_menu.allpages && _menu.allpages.indexOf(`|${_page}|`) >= 0) ||
            _menu.page === _page
          ) {
            setMenuValue(i + 1, _currMenusel[`menu${i}_child`], _index);
          }
        });
        if (!_currMenusel[`menu${i + 1}_idx`])
          setMenuValue(i + 1, _currMenusel[`menu${i}_child`], 0);
      } else {
        setMenuValue(i + 1, null, 0);
      }
    }
    return _currMenusel;
  }

  findMenuByKeyword(_keyword) {
    let _menuTemp = []
    let _sideMenu = this.sideMenu.slice(0)
    if (!_keyword) {
      _sideMenu.forEach(_menu1 => {
        _menu1.matchCount = null
      })
      return _sideMenu
    }
    let _keyReg = new RegExp(_keyword,'i')
    _sideMenu.forEach((_menu1) => {
      if (_menu1.childMenu && _menu1.childMenu.length > 0) {
        let _matchMenu = 0
        _menu1.childMenu.forEach(_menu2 => {
          if (_menu2.name.match(_keyReg) !== null) {
            _matchMenu += 1
            if (_menuTemp.indexOf(_menu1) < 0) _menuTemp.push(_menu1)
          }
        })
        _menu1.matchCount = _matchMenu
      }
    })
    return _menuTemp
  }

  searchMenuByKeyword(_keyword){
    let _menuTemp = []
    let _sideMenu = this.sideMenu.slice(0)
    if (!_keyword) {
      return _menuTemp
    }
    let _keyReg = new RegExp(_keyword, 'i')
    function searchMenu(_menuArr, _level, _parentName){
      _menuArr.forEach(_menu => {
        if (_level > 2 && _menu.name.match(_keyReg) !== null) {
          let _temp = _menu
          _temp.parentName = _parentName
          _menuTemp.push(_temp)
        }
        if (_menu.childMenu && _menu.childMenu.length > 0) {
          searchMenu(_menu.childMenu, _level + 1, _level > 1 ? (_parentName ? (_parentName + '·' + _menu.name) : _menu.name) : '')
        }
      })
    }
    searchMenu(_sideMenu,1)
    return _menuTemp
  }

  setMenuSelect(_page) {
    if (!_page && $('.subMenu .subMenu--title-wl').length > 0) return;
    let _matchUrl = this.setMenuSelectAdvanced()
    _page = _page ? _page : _matchUrl;
    if (!_page ||
      (_currPageSel === _page &&
        currentToggleStatus !== ToggleStatus.MainHide$SubHide_status1)
    ) return;
    if (_page)
      $(".YnaviHead a[data-page='outline']")
        .parent()
        .removeClass('cur');

    const _currHash = location.hash.substring(1);
    const _temps = templates.SubMenuNew;
    let _currMenusel = this.findMenuByPage(_page);
    if(!_currMenusel) return;
    // 添加最近操作
    if(!utils.isNull(_currMenusel.menu2_page)){
      this.addVisitedMenu(_currMenusel.menu2_page)
    }

    _curr = _currMenusel;
    _currPageSel = !utils.isNull(_currMenusel.menu4_page) ?
      _currMenusel.menu4_page :
      !utils.isNull(_currMenusel.menu3_page) ?
        _currMenusel.menu3_page :
        !utils.isNull(_currMenusel.menu2_page) ?
          _currMenusel.menu2_page :
          _currMenusel.menu1_page;
    utils.setCookie('currPage', _page);
    if (utils.isNull(_currMenusel.menu3_name)) {
      currentToggleStatus = ToggleStatus.MainSpread$SubHide_status3;
      $('.YnaviContent').attr('class', 'YnaviContent status3');
      $('.subMenu')
        .addClass('none')
        .removeClass('spread');
    } else {
      currentToggleStatus = ToggleStatus.MainSpread$SubHide_status2;
      $('.YnaviContent').attr('class', 'YnaviContent status2');
      $('.subMenu')
        .removeClass('none')
        .addClass('spread');
    }
    if (_currMenusel.menu3_arr && _currMenusel.menu3_arr.length > 0) {
      if (
        $(`.subMenu a[data-page='${_currMenusel.menu3_page}']`).length <= 0 ||
        $(`.subMenu a[data-page='${_currMenusel.menu4_page}']`) <= 0
      ) {
        $('.subMenu')
          .html(
            _temps({
              subTitle: _currMenusel.menu2_name,
              subMenu: _currMenusel.menu3_arr,
              getTranslation: getTranslation
            })
          )
          .removeClass('none')
          .addClass('spread');
      }
      $('.subMenu a').removeClass('cur');
      $('.subMenu li').removeClass('open');

      if (utils.isNull(_currMenusel.menu4_page)) {
        $(`.subMenu a[data-page='${_currMenusel.menu3_page}']`).addClass('cur');
      } else {
        if (utils.isNull(_currMenusel.menu5_page)) {
          $(`.subMenu a[data-page='${_currMenusel.menu3_page}']`)
            .addClass('cur')
            .parent()
            .addClass('open');
          $(`.subMenu a[data-page='${_currMenusel.menu4_page}']`).addClass('cur');
        } else {
          $(`.subMenu a[data-page='${_currMenusel.menu4_page}']`)
            .addClass('cur')
            .parent()
            .addClass('open');
          $(`.subMenu a[data-page='${_currMenusel.menu5_page}']`).addClass('cur');
        }
      }
    }
    $('.mainMenu, .subMenu, .mainCon').addClass('trans');
    function scrollTop() {
      const currentMenu = $('.subMenu a.cur')?.get(0)
        currentMenu?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        })
    }

    scrollTop()
    this.setSubMeMenuTop()
    // if ($('.subMenu .subMenu--title').length > 0) $('.subMenu .subMenuBox').css('top', ($('.subMenu .subMenu--title').get(0).offsetHeight + 1) + 'px')
    // _naviObj.setMainMenuPosition(_currLi);
  }

  setSubMenuStatus() {
    if (_curr !== null) {
      const _currLi = $(
        `.mainMenu a[data-page='${_curr.menu1_page}']`
      ).parent();
      $(_currLi)
        .addClass('cur')
        .find('div')
        .height(
          $(_currLi)
            .find('ul')
            .height()
        );
      $(_currLi)
        .find(`a[data-page='${_curr.menu2_page}']`)
        .parent()
        .addClass('cur');
      if (utils.isNull(_curr.menu3_name)) {
        $(`.subMenu a[data-page='${_curr.menu2_page}']`).addClass('cur');
      } else {
        $(`.subMenu a[data-page='${_curr.menu2_page}']`)
          .addClass('cur')
          .parent()
          .addClass('open');
        $(`.subMenu a[data-page='${_curr.menu3_page}']`).addClass('cur');
      }
    }
  }

  getCurrPageObj(_idx) {
    _idx = _idx ? _idx.split(',') : [0, 0, 0, 0];
    let _h, _i, _j, _k;
    const _return = {};
    _h = _idx[0] ? Number(_idx[0]) : 0;
    _i = _idx[1] ? Number(_idx[1]) : 0;
    _j = _idx[2] ? Number(_idx[2]) : 0;
    _k = _idx[3] ? Number(_idx[3]) : 0;
    _return.canFind = true;
    _return.levenIdx = [_h];
    _return.menu1_idx = _h;
    _return.menu1_name = this.sideMenu[_h].name;
    _return.menu1_page = this.sideMenu[_h].page;

    _return.menu2_arr = this.sideMenu[_h].childMenu;
    _return.menu2_idx = _i;
    _return.menu2_name = _return.menu2_arr[_i].name;
    _return.menu2_page = _return.menu2_arr[_i].page;

    _return.menu3_arr =
      _return.menu2_arr[_return.menu2_idx].childMenu &&
        _return.menu2_arr[_return.menu2_idx].childMenu.length > 0 ?
        _return.menu2_arr[_return.menu2_idx].childMenu :
        null;
    _return.menu3_idx = _j;
    _return.menu3_name = _return.menu3_arr ? _return.menu3_arr[_j].name : '';
    _return.menu3_page = _return.menu3_arr ? _return.menu3_arr[_j].page : '';

    _return.menu4_arr =
      _return.menu3_arr &&
        _return.menu3_arr[_return.menu3_idx].childMenu &&
        _return.menu3_arr[_return.menu3_idx].childMenu.length > 0 ?
        _return.menu3_arr[_return.menu3_idx].childMenu :
        null;
    _return.menu4_idx = _k;
    _return.menu4_name = _return.menu4_arr ? _return.menu4_arr[_k].name : '';
    _return.menu4_page = _return.menu4_arr ? _return.menu4_arr[_k].page : '';
    return _return;
  }

  getSubMenu(_page) {
    for (let i = 0; i < this.sideMenu.length; i++) {
      if (this.sideMenu[i].page === _page) {
        return this.sideMenu[i].childMenu &&
          this.sideMenu[i].childMenu.length > 0 ?
          this.sideMenu[i].childMenu :
          null;
      }
      if (this.sideMenu[i].childMenu && this.sideMenu[i].childMenu.length > 0) {
        let h = 0;
        const hlen = this.sideMenu[i].childMenu.length;
        for (; h < hlen; h++) {
          if (this.sideMenu[i].childMenu[h].page === _page) {
            return this.sideMenu[i].childMenu;
          }
          if (
            this.sideMenu[i].childMenu[h].childMenu &&
            this.sideMenu[i].childMenu[h].childMenu.length > 0
          ) {
            let j = 0;
            const jlen = this.sideMenu[i].childMenu[h].childMenu.length;
            for (; j < jlen; j++) {
              if (this.sideMenu[i].childMenu[h].childMenu[j].page === _page) {
                return this.sideMenu[i].childMenu;
              }
            }
          }
        }
      }
    }
    return null;
  }

  copyAccount(text) {
    const textareaC = document.createElement('textarea');
    textareaC.setAttribute('readonly', 'readonly'); //设置只读属性防止手机上弹出软键盘
    textareaC.value = text;
    document.body.appendChild(textareaC); //将textarea添加为body子元素
    textareaC.select();
    document.execCommand('copy');
    document.body.removeChild(textareaC);//移除DOM元素
  }
  //复制账号id
  copyAccountId(_event) {
    const text = document.getElementById("accountId").textContent
    this.copyAccount(text)
    const tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "复制成功";
  }
  // 重置复制的样式
  resetCopyAccountId() {
    const tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "复制";
  }
  //子用户登陆时候复制主账号id
  copyAdminAccountId(_event) {
    const text = document.getElementById("accountAdminId").textContent
    this.copyAccount(text)
    const tooltip = document.getElementById("adminTooltip");
    tooltip.innerHTML = "复制成功";
  }
  // 重置复制的样式
  resetAdminAccountId() {
    const tooltip = document.getElementById("adminTooltip");
    tooltip.innerHTML = "复制";
  }

  headerLinkEvt(_event) {
    _event = window.event || _event;
    if (_event.preventDefault) {
      _event.preventDefault();
    } else {
      _event.returnValue = false;
    }
    if ($(this).hasClass('btn-product-close')) return false;
    const _li = $(this).parents('li'),
      _dropLi = $(_li).siblings('.hover'),
      _page = $(this).attr('data-page'),
      _url = $(this).attr('data-url'),
      _target = $(this).attr('target');
    if ($(this).parents('.product').length > 0) return;
    if ($(this).parents('.userCenter').length > 0) return;
    if ($(this).parent().hasClass('auto')) return;
    if ($(this).hasClass('logo')) {
      utils.openUrl($(this).attr('href'), '_blank');
      return;
    }
    if ($(this).hasClass('dropLink')) {
      if ($(_li).hasClass('hover')){
        $(_li).removeClass('hover')
        if (_page == 'product') $('.YnaviHead').css('z-index','300')
      }
      else{
        $(_dropLi).removeClass('hover');
        $(_li).addClass('hover');
        if (_page == 'product') $('.YnaviHead').css('z-index', '30000')
      }
    } else {
      if (_callbackObj[_page]) _callbackObj[_page]();
      if (utils.isNull(_target)) {
        utils.openUrl(_url);
      } else {
        utils.openUrl(_url, _target);
      }
    }
    return;
  }
  handleClickCommonService(){
    const _pageUrl = $(this).attr('data-url');
    const _newTab =  $(this).attr('data-tab');

    if(_newTab==="1"){
      window.open(_pageUrl,'_blank')
    }else{
      window.location.href = _pageUrl;
    }
  }
  headerLinkOverEvt(_event) {
    let _link = this
    if ($(this).hasClass('onFocuse') || $(this).hasClass('hover')) return
    if ($(_link).attr('data-page') === 'product') return;
    _dropMenuHandle = setTimeout(() => {
      if ($(_link).hasClass('auto')) $(_link).addClass('hover')
    }, 100)
  }

  headerLinkOutEvt(_event) {
    if (_dropMenuHandle) clearTimeout(_dropMenuHandle)
  }

  headerLinkLeaveEvt(_event) {
    if ($(this).hasClass('auto') && !$(this).hasClass('flow-wrap')) $(this).removeClass('hover')
  }

  headerSearchInputEvt() {
    let _box = $(this).parents('.menuSearchbox,.topSearchBox')
    $(_box).addClass('hover')
    if ($(this).parents('.topSearchBox').length > 0) {
      $(this).parents('.topSearchBox').siblings('li').removeClass('hover')
    }
  }

  headerSearchInputOutEvt() {
    let _box = $(this).parents('.menuSearchbox,.topSearchBox')
    $(_box).removeClass('hover')
  }

  headerSearchFormEvt(_event) {
    if ($('header .topSearchBox input').val() === '') return false
  }


  getSearchMenu(_val){
    let _mainMenuTemp = _naviObj.findMenuByKeyword(_val)
    let _otherMenuTemp = _naviObj.searchMenuByKeyword(_val)
    let _maninMenu = [], _ohterMenu = []
    _mainMenuTemp.forEach((_menu) => {
      let _menuTemp = { name: _menu.name, url: _menu.url, childMenu: []}
      _menu.childMenu.forEach(_childMenu=>{
        if (_childMenu.name.includes(_val)) _menuTemp.childMenu.push({ name: _childMenu.name, url: _childMenu.url || _childMenu.childMenu[0].url || _childMenu.childMenu[0].childMenu[0].url })
      })
      _maninMenu.push(_menuTemp)
    })
    _otherMenuTemp.forEach((_menu)=>{
      _ohterMenu.push({ name: _menu.name, url: _menu.url || _menu.childMenu[0].url, parentName: _menu.parentName})
    })
    return { mainMenu: _maninMenu, otherMenu: _ohterMenu }
  }

  searchMenu(_val) {
    _val = _val.replace('/', '').replace('\\', '')
    let _title = $('header .product .searchTitle')
    let _titleMore = $('header .product .searchMoreTitle')
    let _menuTemp = [], _menuSearhed = []
    let _keywordReg = new RegExp(_val, 'i')
    _menuTemp = _naviObj.findMenuByKeyword(_val)
    _menuSearhed = _naviObj.searchMenuByKeyword(_val)
    _val === '' ? $(_title).hide() : $(_title).show()
    $(_title).text(_val === '' ? '' : (_languageId === 'cn' ? (_menuTemp.length > 0 ? `搜索与 “${_val}” 相关产品如下：` : `未搜索到与“${_val}” 相关云服务`) : (_menuTemp.length > 0 ? `Search result: ` : `No result`)))
    // 插入产品模板
    const dropMenuHtml = templates.DropMenuList({
      menu: _menuTemp,
      languageId: _languageId,
      keyword: _keywordReg,
      favMenuKey: _favMenuPages,
      getTranslation: getTranslation
    });
    if (_menuSearhed.length > 0){
      _titleMore.show()
      let _newSearchMenu = []
      _menuSearhed.forEach((_menu)=>{
        if (_menu.url) _newSearchMenu.push(_menu)
      })
      $(".searchMoreMenu").html(templates.searchMenuList({ searchMenu: _newSearchMenu})).show()
    }
    else{
      _titleMore.hide()
      $(".searchMoreMenu").html('').hide()
    }
    $('.productBox .dropMenuBox').html(dropMenuHtml);
    new Masonry(document.querySelector('header .productBox .dropMenuBox'), {
      itemSelector: '.pro-item',
      columnWidth: _languageId === 'en' ? 250 : 200,
      fitWidth: false,
      gutter: _languageId === 'en' ? 20 : 48
    });
    setTimeout(() => {
      // $('.productBox .dropMenuBox .pro-item-line').each(function () {
      //   $(this).html($(this).html().replace(_val, '<i>' + _val + '</i>'))
      // })

      // $('.productBox .dropMenuBox .pro-item').each(function() {
      //     $(this).height((34 + $(this).find(li).length * 30) + px)
      //         // $(this).html($(this).html().replace(_val, '<i>' + _val + '</i>'))
      // })

      $('.productBox .dropMenuBox a.noTag, .productBox .dropMenuBox a.hasTag span.txt').each(function () {
        let _txt = $(this).html();
        let _regArr = _txt.match(_keywordReg)
        if (_regArr !== null) {
          $(this).html(_txt.replace(_keywordReg, '<i>' + _regArr[0] + '</i>'))
        }
      })
      $('.productBox .menuListbox a').each(function () {
        let _txt = $(this).html()
        let _txt1 = _txt.substring(0, _txt.lastIndexOf('</span>') + 7),
          _txt2 = _txt.substring(_txt.lastIndexOf('</span>') + 7)
        let _regArr = _txt2.match(_keywordReg)
        if (_regArr !== null) {
          $(this).html(_txt1 + _txt2.replace(_keywordReg, '<i>' + _regArr[0] + '</i>'))
        }
      })
      // 瀑布流布局初始化

    }, 20);
  }

  headerSearchInputChangeEvt() {
    let _val = $(this).val()
    if ($(this).parents('.topSearchBox').length > 0){
      let _box = $(this).parents('.topSearchBox')
      if(_val === ''){
        $(_box).removeClass('noDrop')
      } else {
        $(_box).addClass('noDrop')
      }
      return
    }
    _naviObj.searchMenu(_val)
  }

  accountSel(_event) {
    _event = window.event || _event;
    if (_event.stopPropagation) _event.stopPropagation();
    if (_event.cancelBubble) _event.cancelBubble = true;
    const _name = _userPin + 'adminPin';
    const _val = $(this).attr('data-pin');
    let _menuParam;
    $('header .company ul a').removeClass('cur');
    $(this).addClass('cur');
    $('header .accStatus dl').html(
      templates.ChildAccount({
        pin: _userPin,
        childNick: _userNick,
        parentNick: $(this).html(),
        getTranslation: getTranslation
      })
    );
    $('header .account, header .company').hide();
    $("header .accStatus a[data-page='toggleStatus']").hide();
    $('header .accStatus').show();
    const adminPinInput = $("input[id='adminPin']");
    if (adminPinInput.length > 0) {
      adminPinInput.val(_val);
    } else {
      $('.mainCon').append(
        `<input type="hidden" id="adminPin" value="${_val}">`
      );
    }
    utils.setCookie(_name, _val);
    _userConfig.adminPin = _val;
    const configData = ConfigMgr.get('config');
    _menuParam = configData.menuConfig;
    _naviObj.sideMenu = _menuParam.sideMenu;
    _naviObj.fillSideMenu();
    $("header a[data-page='outline']")
      .parent('li')
      .hide();
    $("header a[data-page='csmManage']")
      .parent('li')
      .hide();
    _naviObj.toUrl('//console.' + env.domain + '/gz/containers');
    return;
  }

  userCenterEvt(_event) {
    _event = window.event || _event;
    if (_event.preventDefault) _event.preventDefault();
    // if (_event.stopPropagation) _event.stopPropagation();
    // if (_event.cancelBubble) _event.cancelBubble = true;
    const _page = $(this).attr('data-page');
    let _menuParam;
    if (_page === 'childAccount') return;
    if (_page === 'toggleAcc') {
      if ($('.company ul a').length > 1) {
        $("header .accStatus a[data-page='toggleStatus']").show();
        $('header .account').hide();
        $('header .company').show();
      } else if ($('.company ul a').length === 1) {
        const _accLink = $('.company ul a').eq(0);
        $(_accLink).click();
      }
      return;
    }
    if (_page === 'toggleStatus') {
      $('header .accStatus').hide();
      $('header .company').show();
      return;
    }
    if (_page === 'logout') {
      _naviObj.logout();
      return;
    }
    if (_page === 'backCompany') {
      $('header .company').hide();
      $('header .account').show();
      return;
    }
    if (_page === 'subPermission') {
      utils.openUrl('//uc.jdcloud.com/subaccount/subaccount-permission', '_blank');
      return;
    }
    if (_page === 'subAccesskey') {
      utils.openUrl('//uc.jdcloud.com/subAccount/accessKey', '_blank');
      return;
    }
    if (_page === 'subSecurity') {
      utils.openUrl('//uc.jdcloud.com/subaccount/subaccount-security', '_blank');
      return;
    }
    if (
      _page === 'accountManage' ||
      _page === 'accountSafe' ||
      _page === 'detail' ||
      _page === 'accessControl' ||
      _page === 'recharge'||
      _page === 'verify'
    ) {
      utils.openUrl($(this).attr('data-url'), '_blank');
      return;
    }
    if (_page === 'backStatus') {
      $("header a[data-page='outline']")
        .parent('li')
        .hide();
      $('header .accStatus').hide();
      $('header .account').show();
      $("input[id='adminPin']").val('');
      utils.delCookies(`${_userPin}adminPin`);
      _userConfig.adminPin = '';
      $('.company ul a').removeClass('cur');
      const configData = ConfigMgr.get('config');
      _menuParam = configData.menuConfig;
      _naviObj.sideMenu = _menuParam.sideMenu;
      _naviObj.fillSideMenu();
      _naviObj.toUrl(overviewUrl);
      return;
    }
    if (_page === 'roleLogin') {
      // //uc.jdcloud.com
      location.href = `${subUserLoginUrl}/u/subAccount/role/login`;
      return;
    }
    if (_page === 'roleLogout') {
      $.ajax({
        type: 'GET',
        url: `${mainLoginUrl}/role/logout`, // login.jdcloud.com
        data: {},
        dataType: 'jsonp',
        success(_data) {
          location.href = overviewUrl;
        }
      });
      return;
    }
    if (_page === 'roleAssume') {
      let _roleInfo = $(this).html();
      $.ajax({
        type: 'GET',
        url: `${subUserLoginUrl}/u/subAccount/role/assume`,
        data: {
          roleSessionName: _roleInfo.substr(0, _roleInfo.indexOf('/')),
          roleAccountId: _roleInfo.substr(_roleInfo.indexOf('/') + 1)
        },
        dataType: 'jsonp',
        success(_data) {
          if (_data.code === 0) {
            location.href = overviewUrl;
          }
        }
      });
    }
    return;
  }

  hideDropMenu(_event) {
    _event = window.event || _event;
    //_event.preventDefault()
    const _target = _event.target || _event.srcElement;
    if ($('.YnaviHead').hasClass('onGuide')) return;
    if (
      $(_target).hasClass('dropLink') ||
      $(_target).parents('.dropLink').length > 0 ||
      $(_target).hasClass('userCenter') ||
      $(_target).parents('.userCenter').length > 0 ||
      $(_target).parents('.menuSearchbox').length > 0 ||
      $(_target).parents('.topSearchBox').length > 0
    ) {
      return;
    }
    if ($(_target).parents('.product').length > 0 && !$(_target).hasClass('btn-product-close')){
      return;
    }
    if (($(_target).parents('header').length > 0 && (_target.tagName === 'A' || $(_target).parents('a').length > 0) && !$(_target).hasClass('btn-product-close'))
    ) {
      return;
    }
    $('header li').each(function () {
      // if ($(this).hasClass('hasChild')) {
      $(this).removeClass('hover');
      // }
    });
    $('.YnaviHead').css('z-index','300')
    if ($('header .menuSearchbox input').val() !== '') {
      $('header .menuSearchbox input').val('')
      _naviObj.searchMenu('')
    }
    return;
  }

  /**
   * 子菜单事件
   * @return {boolean}
   */
  subMenuEvt(_event) {
    _event = window.event || _event;
    if (_event.preventDefault) { _event.preventDefault(); }
    if (window.event) window.event.returnValue = false;
    try{
      $('header ul li').removeClass('hover');
      // 2023704 产品 秦传美要求去掉这个逻辑
      // $('.subMenu .menuBox').css('margin-top', 0);

      const _isThirdMenu = $(this).parents('.subInMenu').length > 0 ? true : false;
      const _page = $(this).attr('data-page');
      const _pageUrl = $(this).attr('data-url');
      const _pagePath = $(this).attr('data-path');
      const _newTab =  $(this).attr('data-tab');
      // crossRecommendation：1 为交叉推荐 ，2 为分组的标记「zhangwenxuan&sunxiaobo7说取这个字段、后端不用开发」。
      const _crossRecommendation =  $(this).attr('data-cross-recommendation');


      if ($(this).hasClass('hasChild')) {
        if ($(this).parent('li').hasClass('open')) {
          $(this).parent().removeClass('open');
        } else {
          // 没有选中状态的展开菜单只能同时出现一个
          $('.subMenu ul li').find('a:not(.cur)').parent().removeClass('open')
          $(this).parent().addClass('open');
        }
        return false;
      }
      try {
        if (env.isUc) {
          // Cookies.set('ucCurrPage', _page, { domain: '.jdcloud.com' });
          utils.setCookie('ucCurrPage', _page)
        }
        else {
          if ($('.subMenu .subMenu--title-wl') < 1) {
            // Cookies.set('currPage', _page, { domain: '.jdcloud.com' });
            utils.setCookie('currPage', _page)
          }
        }
      }
      catch(_err){
        console.log(_err)
      }
      try{
        if (_isThirdMenu) {
          $(this).parents('ul').find('a').removeClass('cur');
          $(this).parents('.subInMenu').prev('a').addClass('cur');
        } else {
          $(this).parents('ul').find('li').removeClass('open');
          $(this).parents('ul').find('a').removeClass('cur');
        }
        $(this).addClass('cur');
      }
      catch(_err){
        console.log(_err)
      }
      // newTab 值为1 新开页面，值 0 当前页面打开
      if(_newTab === '1' || _crossRecommendation === '1'){
        utils.openUrl(_pageUrl, '_blank');
      } else {
        _naviObj.toUrl(_pageUrl, _page, _pagePath);
      }
    }
    catch (_err) {
      return false;
    }
    return false;
  }

  addVisitedMenu(_page){
    if(utils.isNull(_page))return
    let _visitedMenu = Cookies.get(_userPin + 'visitedMenu')
    if (utils.isNull(_visitedMenu)) {
      // Cookies.set('visitedMenu', _page, { domain: '.jdcloud.com' })
      utils.setCookie(_userPin + 'visitedMenu', _page)
    }
    else {
      let _visitedArr = _visitedMenu.split(',')
      if (_visitedArr.indexOf(_page) >= 0){
        _visitedArr.splice(_visitedArr.indexOf(_page),1)
      }
      _visitedArr.unshift(_page)
      if (_visitedArr.length > 8){
        _visitedArr = _visitedArr.slice(0, 8)
      }
      _visitedMenu = _visitedArr.join(',')
      // Cookies.set('visitedMenu', _visitedMenu, { domain: '.jdcloud.com' })
      utils.setCookie(_userPin + 'visitedMenu', _visitedMenu)
    }
    this.fillVisitedMenu()
  }

  dropMenuEvt(_event) {
    _event = window.event || _event;
    if (_event.preventDefault) _event.preventDefault();
    // if (_event.stopPropagation) _event.stopPropagation();
    // if (_event.cancelBubble) _event.cancelBubble = true;
    if ($(this).hasClass('btn-product-close')) return false;
    const _page = $(this).attr('data-page');
    const _pageUrl = $(this).attr('data-url');
    const _serviceCode = $(this).attr('data-code');
    const _href = $(this).attr('href');
    const _newTab = $(this).attr('data-tab');
    const _pagePath = $(this).attr('data-path');
    try{
      if ($(this).parents('.dropMenuBox').length > 0) {
        _naviObj.addVisitedMenu(_page)
      }
    }
    catch(_err){}
    if (_pageUrl) {
      utils.setCookie('currPage', _page);
      // _naviObj.toUrl(_pageUrl, _page);
      if(_newTab==="1"){
        window.open(_pageUrl,'_blank')
      } else {
        $("header li[data-page='product']").removeClass('hover')
        if (window.onChangePath && _pagePath) {
          window.onChangePath(_pagePath);
          _naviObj.setCurrPage(_page)
          return;
        }
        window.location.href = _pageUrl;
      }
    } else {
      window.open(_href, '_blank');
    }
    $(document.body).click();
    return false;
  }

  spreadMenu() {
    const _li = $(this).parent();
    const _selLi = $(_li).siblings('.cur');
    $('.mainMenu .menuBox').css('margin-top', 0);
    $(_selLi)
      .find('div')
      .css('overflow', 'hidden');
    $(_selLi)
      .removeClass('cur')
      .find('div')
      .height(0);
    if ($(_li).hasClass('cur')) {
      $(_li)
        .find('div')
        .css('overflow', 'hidden');
      $(_li)
        .removeClass('cur')
        .find('div')
        .height(0);
    } else {
      $(_li)
        .addClass('cur')
        .find('div')
        .height(
          $(_li)
            .find('ul')
            .height()
        );
    }
    _naviObj.setMainMenuPosition(_li);
    return;
  }

  setMainMenuPosition(_li) {
    let _liTop = isNaN(Number($(_li).attr('data-index'))) ?
      $(_li).offset().top :
      Number($(_li).attr('data-index')) * 40 + 40,
      _liInnerHeight = $(_li)
        .find('ul')
        .height(),
      _liHeight = ($(_li).hasClass('cur') ? _liInnerHeight : 0) + 40,
      _listHeight =
        $('.mainMenu .menu1').length * 40 +
        ($(_li).hasClass('cur') ? _liInnerHeight : 0),
      _boxHeight = $('.mainMenu').height(),
      _listMarginTop =
        _liTop + _liHeight > _boxHeight ? _boxHeight - (_liTop + _liHeight) : 0;
    if (
      _listMarginTop < 0 &&
      _listHeight > _boxHeight &&
      _listMarginTop + _listHeight < _boxHeight
    )
      _listMarginTop = _boxHeight - _listHeight;
    if (_listHeight <= _boxHeight) _listMarginTop = 0;
    $('.mainMenu .menuBox').css('margin-top', _listMarginTop + 'px');
  }

  setSubMenu() {
    const _page = $(this).attr('data-page');
    const _url = _sideMenuKeyObj[_page].url;
    // utils.openUrl(_url);
    _naviObj.toUrl(_url, _page);
    return;
  }

  toggleSubMenu_n() {
    $('.subMenu').toggleClass('spread');
    if ($('.subMenu').hasClass('spread')) {
      currentToggleStatus = ToggleStatus.MainCollapse$SubSpread_status2;
      $('.YnaviContent').attr('class', 'YnaviContent status2');
    } else {
      currentToggleStatus = ToggleStatus.MainCollapse$SubHide_status3;
      $('.YnaviContent').attr('class', 'YnaviContent status3');
    }
    return;
  }

  toSubMenu() {
    const _page = $(this).attr('data-page');
    _subTitle = $(this)
      .find('span')
      .html();
    _subMenuObj = _naviObj.getSubMenu(_page);
    $('.mainMenu a').removeClass('cur');
    $(this).addClass('cur');
    if (utils.isNull(_subMenuObj)) {
      location.hash = _page;
      if (parseInt($('.subMenu').css('margin-left')) === 0) {
        $('.subMenu').animate({
          marginLeft: '-149px'
        }, 300);
        $('.mainCon').animate({
          marginLeft: '51px'
        }, 300);
      }
      let subMenuTime = setTimeout(function () {
        $('.subMenu').html('');
        clearTimeout(subMenuTime);
      }, 300);
    } else {
      if (parseInt($('.subMenu').css('margin-left')) === -149) {
        $('.subMenu').animate({
          marginLeft: '0'
        }, 300);
        $('.mainCon').animate({
          marginLeft: '200px'
        }, 300);
      }
      $('.subMenu').html(
        templates.SubMenuNew({
          subTitle: _subTitle,
          subMenu: _subMenuObj,
          getTranslation: getTranslation
        })
      );
      $('.subMenu a').each(function () {
        if (!utils.isNull($(this).attr('data-url'))) _naviObj.urlReplace(this);
      });
      // if ($('.subMenu .subMenu--title').length > 0) $('.subMenu .subMenuBox').css('top', ($('.subMenu .subMenu--title').get(0).offsetHeight + 1) + 'px')
      this.setSubMeMenuTop()
      if (
        $('.subMenu a')
          .eq(0)
          .hasClass('hasChild')
      ) {
        $('.subMenu a')
          .eq(0)
          .addClass('cur')
          .parent()
          .addClass('open')
          .find('ul a')
          .eq(0)
          .click();
      } else {
        $('.subMenu a')
          .eq(0)
          .click();
      }
    }
    return;
  }

  addClick(_page, _callback, _cancelDefault) {
    _callbackObj[_page] = {};
    _callbackObj[_page].callback = _callback;
    _callbackObj[_page].cancelDefault = _cancelDefault; //true:false是否禁用默认事件
  }

  ssoCallBackFunc(force) {
    store.remove('YnaviConfig');
    store.remove('YnaviConfigLastModified');
    try {
      window.sendMessageToTab({
        type: "LogOut",
        data: "current user has logout"
      });
    } catch (e) {}
    window.location.replace(`${subUserLoginUrl}/redirect/quit?returnUrl=${encodeURIComponent(location.href)}`);
    if (window.event) window.event.returnValue = false;
  }

  adminRoleCallbackFun() {
    location.href = `${subUserLoginUrl}/subAccount/login/${ _companyName }`;
  }

  logout() {
    if (_role && (_role.type == 'service' || _role.type == 'federated')) {
      $.ajax({
        type: 'GET',
        url: `${mainLoginUrl}/role/logout`,
        data: {},
        dataType: 'jsonp',
        success(_data) {
          window.location.href = _data.roleLoginUrl;
        }
      });
      // let redirectUrl = Cookies.get('r_url');
      // window.location.href = redirectUrl + (_role.type == 'service' ? `?returnUrl=${encodeURIComponent(window.location.href)}` : '');
      return;
    }
    // const _adminPin = Cookies.get('account');
    let promises = [],
      rquestUrl = [];
    if (!utils.isNull(_adminPin) || (_role && _role.type === 'user')) {
      rquestUrl = [
        `${mainLoginUrl}/role/logout`,
        `${subUserLoginUrl}/subAccount/logout`,
      ]
      for (let i = 0; i < rquestUrl.length; i++) {
        let logoutP = jsonp(rquestUrl[i], {
          timeout: 1000
        })
        promises.push(logoutP.promise)
      }
      Promise.all(promises).then(() => {
        _naviObj.adminRoleCallbackFun()
      }, () => {
        _naviObj.adminRoleCallbackFun()
      })
      return;
    }
    rquestUrl = [
      `${subUserLoginUrl}/u/subAccount/role/logout`,
      `${mainLoginUrl}/logout`,
    ]
    if (!isPrivate) {
      rquestUrl.push('//sso.jd.com/exit',
        '//sso.jcloud.com/exit',
        '//sso.jdcloud.com/exit',
        '//sso.360buy.com/exit',
        '//sso.wangyin.com/exit',
        '//baas-console.jdcloud.com/logout',
        '//plogin.m.jd.com/cgi-bin/ml/mlogout?appid=580')
    }
    for (let i = 0; i < rquestUrl.length; i++) {
      let logoutP = jsonp(rquestUrl[i], {
        timeout: 1000
      })
      promises.push(logoutP.promise)
    }
    Promise.all(promises).then(() => {
      _naviObj.ssoCallBackFunc()
    }, () => {
      _naviObj.ssoCallBackFunc()
    })
  }

  hideLink(name) {
    const link = $('header, .mainMenu, .subMenu').find(
      `a[data-page='${name}']`
    );
    if (link.length <= 0) return;
    $(link)
      .parents('li')
      .hide();
  }

  setRoleInfo() {
    let _account = decodeURIComponent(Cookies.get('account'));
    let _roleInfo = decodeURIComponent(Cookies.get('role'));
    _role_history = decodeURIComponent(Cookies.get('role_history'));
    if (!utils.isNull(_account)) {
      _account = _account.replace(/\+/g, ' ');
      _companyName = _account.substring(_account.indexOf('@') + 2);
      _adminPin = _account.substring(0, _account.indexOf('@') - 1);
    }
    //_role, _role_history 角色信息
    if (!utils.isNull(_roleInfo)) {
      _role = {};
      _roleInfo = _roleInfo.replace(/\+/g, ' ');
      let _roleInfoArr = _roleInfo.split(/ @ |\\/);
      if (_roleInfoArr[0] === 'user') {
        _role.type = 'user'
        _role.accountId = _roleInfoArr[1];
        _role.roleName = _roleInfoArr[3];
      } else if (_roleInfoArr[0] === 'service') {
        _role.type = 'service'
        _role.accountId = _roleInfoArr[1]; // 账户
        _role.rolePin = _roleInfoArr[4]; // 目录账户
        _role.roleName = _roleInfoArr[2]; // 登录角色
      } else if (_roleInfoArr[0] === 'federated') {
        _role.type = 'federated'
        _role.accountId = _roleInfoArr[1]; // 账户
        _role.roleName = _roleInfoArr[2]; // 登录角色
        _role.roleSession = _roleInfoArr[3]; // 有效会话
        let _idp = decodeURIComponent(Cookies.get('role_idp'))
        if (!utils.isNull(_idp)) {
          _idp = _idp.split(/ @ |\\/);
          _role.idpName = _idp[1]
          _role.idpAccount = _idp[0]
        }
      } else {
        _role.type = 'user'
        _role.accountId = _roleInfoArr[0];
        _role.roleName = _roleInfoArr[1];
      }

      if (
        _role_history &&
        _role_history !== 'none' &&
        _role_history !== 'undefined'
      ) {
        _role_history = _role_history.split(',');
      } else {
        _role_history = null;
      }
    }
  }
  setUserName(userName) {
    $("header a[data-page='user']").attr('title', userName);
    $("header a[data-page='user'] span").html(userName);
    $("header .userCenter .userPin").html(userName);
  }
  setUserId(accountId) {
    $("header .userCenter .userContent .userId .id").html(accountId);
  }

  // 添加用户状态
  setUserStatus(companyAuthStatus,personAuthStatus) {
      //企业实名: 1.未个人实名，已企业实名，展示为企业实名  2.已个人实名，已企业实名，展示为企业实名 3.未个人实名，已企业实名，个人实名审核中，展示为企业实名
      //企业实名审核中: 4.已经个人实名，未企业实名，企业实名审核中，展示企业实名审核中 5.未个人实名，未企业实名，企业实名审核中，展示企业实名审核中
      // 已个人实名，企业未实名，展示为个人实名
      // 未个人实名，未企业实名，个人实名审核中，展示个人实名审核中
      // 个人，企业均未实名，则展示为未实名
      let status = ''
      if(companyAuthStatus===USER_STATUS.VERIFIED){
        status = USER_STATUS_MPA.COMPANY_VERIFIED
      }
      else if (companyAuthStatus===USER_STATUS.AUDIT) {
        status = USER_STATUS_MPA.COMPANY_AUDIT
      }
      else if (personAuthStatus===USER_STATUS.VERIFIED){
        status = USER_STATUS_MPA.PERSON_VERIFIED
      }
      else if (personAuthStatus===USER_STATUS.AUDIT){
        status = USER_STATUS_MPA.PERSON_AUDIT
      }
      else {
        status = USER_STATUS_MPA.UNVERIFIED
      }
    let statusContent = statusMap[status]
    $("#userStatus .renameType span").html(statusContent.content);
    $("#userStatus .renameType span").addClass(statusContent.style)
    if(status===USER_STATUS_MPA.UNVERIFIED){
      $("header .userCenter .userInfo .userTip").show()
    }
  }
  getHideSideMenu(){
    const { href } = window.location
    const needHidePaths =  ['/create', 'hideSideMenu', '/commonPay','accountErr', yunjianOverview]
    return window.hideSideMenu === true || needHidePaths.some(p => href.indexOf(p) > 0)
  }
  setCurrPage(page) {
    if (env.isUc) return;
    const isHideSideMenu = this.getHideSideMenu()
    if (isHideSideMenu) {
      currentToggleStatus = ToggleStatus.MainHide$SubHide_status1;
      $('.YnaviContent').attr('class', 'YnaviContent status1');
      _currPageSel = '';
      return;
    }
    // 有page的时候应用老策略
    if (page) {
      this.setMenuSelect(page);
    } else {
      const page = this.setMenuSelectAdvanced();
      console.log(`路径匹配page：${page}`);
      this.currPage = page;
      if (!page &&
        currentToggleStatus === ToggleStatus.MainHide$SubHide_status1
      ) {
        currentToggleStatus = ToggleStatus.MainHide$SubHide_status3;
        $('.YnaviContent').attr('class', 'YnaviContent status3');
      }
      if (!page || page === 'null' || page === 'undefined') return;
      if (page === '$__legacy_impl__$') {
        logger.debug('降级到原选中实现');
        this.setMenuSelect();
      } else {
        logger.debug('自动匹配结果', page);
        this.setMenuSelect(page);
      }
    }
  }

  setAddedMenuSelect(_page){
    if(!_page) return;
    let _selectedOpenMenu = $('.menuBox .hasChild')
    let _selectedMenu = $('.menuBox .cur')
    let _selectMenu = $('.menuBox').find('a[data-page="'+ _page +'"]')
    if(_selectMenu.length < 1) return;
    _currPageSel = _page;
    let _hasChild = $(_selectMenu).hasClass('hasChild');
    let _parentLink = $(_selectMenu).parents('ul').siblings('a');
    let _isChild = _parentLink.length > 0
    if (_selectedOpenMenu.length > 0){
      if (_parentLink.length > 0){
        if($(_selectedOpenMenu).attr('data-page') !== $(_parentLink).attr('data-page')) $(_selectedOpenMenu).parent('li').removeClass('open')
      }
      else{
        $(_selectedOpenMenu).parent('li').removeClass('open')
      }
    }
    $('.menuBox a').removeClass('cur');
    $(_selectMenu).addClass('cur')
    if (_hasChild) {
      $(_selectMenu).parent('li').addClass('open')
      $($(_selectMenu).siblings('ul').find('a')[0]).addClass('cur')
    }
    if(_isChild){
      $(_parentLink).addClass('cur')
      $(_parentLink).parent('li').addClass('open')
    }
  }

  filterUrlStag(hostname) {
    const prefixList = ['pre', 'new', 'stag'];
    prefixList.forEach(prefix => {
      hostname = hostname.replace('-' + prefix, '');
      hostname = hostname.replace(prefix + '-', '');
    });
    hostname = hostname.replace('.jcloud.com', '.jdcloud.com');
    return hostname;
  }

  setMenuSelectAdvanced() {
    let {
      href,
      hostname,
      pathname,
      search: hostSearch
    } = location;
    // href = 'http://console.cn-stag-2.jdcloud.com/host/compute/list'
    // hostname = 'console.cn-stag-2.jdcloud.com'
    // pathname = 'host/compute/list'
    href = href.replace('http:', '').replace('https:', '');
    pathname = pathname.charAt(0) === '/' ? pathname.substr(1) : pathname;
    let hostname_f = this.filterUrlStag(hostname);
    let _canMatchArr = [],
      _canMatchArrTemp = [];
    let _pathnameArr = pathname.split('/');
    for (let _p in _sideMenuKeyObj) {
      const sideMenuObj = _sideMenuKeyObj[_p] || {}
      const hostUrl = sideMenuObj.url
      const { search } = new URL(hostUrl, location.origin)
      // 只判断推荐方式为正常的菜单数据
      if (sideMenuObj.crossRecommendation === 0) {
        if (search && hostUrl) {
          // 只有浏览器地址栏有时，才判断这个逻辑，并且跳过这次匹配
          if (hostSearch && (hostUrl.indexOf(`//${hostname}/${pathname}${hostSearch}`) >= 0 || hostUrl.indexOf(`//${hostname_f}/${pathname}`) >= 0)) {
            return _p
          }
        } else {
          if (hostUrl && (hostUrl.indexOf(`//${hostname}/${pathname}`) >= 0 || hostUrl.indexOf(`//${hostname_f}/${pathname}`) >= 0)) {
            return _p;
          }
        }
      }
    }
    for (let _p in _sideMenuKeyObj) {
      if (
        _sideMenuKeyObj[_p].url &&
        (_sideMenuKeyObj[_p].url.indexOf(`//${hostname}`) >= 0 ||
          _sideMenuKeyObj[_p].url.indexOf(`//${hostname_f}`) >= 0)
      ) {
        _canMatchArr.push({
          page: _p,
          url: _sideMenuKeyObj[_p].url,
          path: _sideMenuKeyObj[_p].path,
          crossRecommendation: _sideMenuKeyObj[_p].crossRecommendation || 0
        });
      }
    }
    if (_canMatchArr.length <= 0) return Cookies.get('currPage') || '';

    function matchUrl(_idx) {
      let _temp = [];
      if (_canMatchArrTemp.length > 0) _canMatchArr = _canMatchArrTemp;
      let _matchUrl = '',
        _matchUrl1 = '';
      for (let i = 0; i <= _idx; i++) {
        _matchUrl += _pathnameArr[i] + (i + 1 < _pathnameArr.length ? '/' : '');
        _matchUrl1 += _pathnameArr[i] + (i < _idx ? '/' : '');
      }
      if (_canMatchArr.length > 0) {
        _canMatchArr.forEach(_menuObj => {
          const _tempIndex = _menuObj.url.indexOf(_matchUrl)
          // 如果原始菜单是/ 结尾，匹配的url也是/结尾，或者原始菜单不是/结尾，匹配的url也不是/结尾
          const matchUrl = _menuObj.url.endsWith('/') ?_matchUrl1.endsWith('/') ? _matchUrl1 : _matchUrl1+'/': _matchUrl1;
          let currentIndex = _tempIndex > 0 ? _tempIndex : _menuObj.url.indexOf(matchUrl);
          // 只处理crossRecommendation为0的菜单数据
          if (!_menuObj.crossRecommendation) {
            // 云舰逻辑, 云舰activeRule 唯一，一级路径可以判断出子应用，jdstack 和公有云一级路径无法覆盖所有菜单场景
            if (isPoweredByApp) {
              if (_menuObj.url && (currentIndex >= 0 || _menuObj.url.indexOf(_matchUrl1) >= 0 || _menuObj.url.toLowerCase().indexOf(_matchUrl.toLowerCase()) >= 0 || _menuObj.url.toLowerCase().indexOf(_matchUrl1.toLocaleLowerCase()) >= 0)) {
                Object.assign(_menuObj,{_matchIndex:currentIndex})
                // 上一次的indexOf值 如果比当前indexOf的值大，则_temp为当前_menuObj
                // eg: xxxxes/.indexOf('es') > xes/.indexOf('es')
                const _matchIndex = _temp[0]?._matchIndex
                if(currentIndex > 0){
                  if (!_matchIndex || _matchIndex > currentIndex) {
                    _temp = [_menuObj]
                  } else {
                    _temp.push(_menuObj)
                  }
                }
              }
            } else {
              if (_menuObj.url && (_menuObj.url.indexOf(_matchUrl) >= 0 || _menuObj.url.indexOf(_matchUrl1) >= 0 || _menuObj.url.toLowerCase().indexOf(_matchUrl.toLowerCase()) >= 0 || _menuObj.url.toLowerCase().indexOf(_matchUrl1.toLocaleLowerCase()) >= 0)) {
                _temp.push(_menuObj);
              }
            }
          }
        });
      }
      if (_temp.length > 0) _canMatchArrTemp = _temp;
    }
    for (let i = 0; i < _pathnameArr.length; i++) {
      matchUrl(i);
      if (_canMatchArrTemp.length > 1) {
        continue;
      } else {
        break;
      }
    }
    if (_canMatchArrTemp.length <= 0) _canMatchArrTemp = _canMatchArr;
    return (
      (_canMatchArrTemp[0] && _canMatchArrTemp[0].page) ||
      Cookies.get('currPage') ||
      ''
    );
  }

  setAccountInfo(balance, arrears) {
    balance = balance.toString();
    arrears = arrears.toString();
    let _idx;
    if (!utils.isNull(balance) && !isNaN(Number(balance))) {
      _idx = balance.indexOf('.');
      const _b1 = _idx > 0 ? balance.substring(0, _idx) : balance;
      let _b2 = _idx > 0 ? balance.substr(_idx + 1, 2) : '00';
      _b2 = _b2.length === 0 ? '00' : _b2.length === 1 ? `${_b2}0` : _b2;
      $('header .userCenter .userBalance').html(
        `<strong>￥${_b1}.${_b2}</strong>`
      );
    }
    if (utils.isNull(arrears) || arrears === 0) {
      $('header .userCenter .arrears').html('');
    } else {
      _idx = arrears.indexOf('.');
      const _a1 = _idx > 0 ? arrears.substring(0, _idx) : arrears;
      let _a2 = _idx > 0 ? arrears.substr(_idx + 1, 2) : '00';
      _a2 = _a2.length === 0 ? '00' : _a2.length === 1 ? `${_a2}0` : _a2;
      $('header .userCenter .arrears .arrears-number').html(`￥${_a1}.${_a2}`);
    }
  }

  setChildAccount(accountArr) {
    $('header .userCenter .btn_accToggle').show();
    $('header .userCenter .company ul').html(
      templates.Account({
        list: accountArr
      })
    );
  }

  setChildAccSel(_pin, _userNick) {
    const _accLink = $(`header .userCenter .company a[data-pin='${_pin}']`);
    $('header .company ul a').removeClass('cur');
    $(_accLink).addClass('cur');
    $('header .accStatus dl').html(
      templates.ChildAccount({
        pin: _userPin,
        childNick: _userNick,
        parentNick: $(_accLink).html(),
        getTranslation: getTranslation
      })
    );
    $('header .account, header .company').hide();
    $("header .accStatus a[data-page='toggleStatus']").hide();
    $('header .accStatus').show();
  }

  topMenuAdd(config) {
    _naviObj.topMenu.splice(config.index - 1, 0, config.menuObj);
    _naviObj.fillTopMenu(true);
  }

  /**
   * 点击链接进行跳转
   * @param link
   * @param _page
   * @returns {boolean}
   */
  toUrl(link, _page, _path) {
    if (!_path)
      _path = env.isUc ?
        _ucSideMenuKeyObj[_page] ?
          _ucSideMenuKeyObj[_page].path :
          null :
        _sideMenuKeyObj[_page] ?
          _sideMenuKeyObj[_page].path :
          null;
    if (_path && _path.charAt(0) !== '/') _path = '/' + _path;
    let _localUrl = this.filterUrlStag(location.hostname);
    let _filterLink = this.filterUrlStag(link);
    // 业务线代码会把toRoutePath挂到window下
    // window.toRoutePath一般是vue-router暴露出来的方法，应当优先使用
    if (!utils.isNull(_path)) {
      if (
        link.indexOf('//' + _localUrl) >= 0 ||
        _filterLink.indexOf('//' + _localUrl) >= 0
      ) {
        // if(window.history){
        //   window.history.pushState(null,null,_path)
        //   return
        // }
        if (window.toRoutePath) {
          window.toRoutePath(_path);
          return;
        }
        location.href = link;
        return;
      }
    }
    utils.openUrl(link);
    return;
  }

  mainMenuScrollEvt(_event) {
    _event = window.event || _event;
    const _scroll = _event.wheelDelta ?
      _event.wheelDelta :
      -(_event.detail || 0) * 9;
    const _mt = parseInt($('.mainMenu .menuBox').css('margin-top'));
    const _mainMenuHeight = $('.mainMenu').height();
    const _menuboxHeight = $('.mainMenu .menuBox').height();
    if (_mt < 0 || _menuboxHeight > _mainMenuHeight) {
      if (_mainMenuHeight > _menuboxHeight) {
        $('.mainMenu .menuBox').css('margin-top', '0px');
        return;
      }

      if (_scroll > 0) {
        $('.mainMenu .menuBox').css(
          'margin-top',
          _mt < 0 ? _mt + _scroll + 'px' : 0
        );
      } else {
        $('.mainMenu .menuBox').css(
          'margin-top',
          _mt > _mainMenuHeight - _menuboxHeight ?
            _mt + _scroll + 'px' :
            _mainMenuHeight - _menuboxHeight + 'px'
        );
      }
    }
  }

  subMenuScrollEvt(event) {
    event = window.event || event;
    const _scroll = event.wheelDelta ?
      event.wheelDelta :
      -(event.detail || 0) * 9,
      _mt = parseInt($('.subMenu .menuBox').css('margin-top')),
      _subMenuHeight = $('.subMenu').height(),
      _menuboxHeight = $('.subMenu .menuBox').height();
    if (_mt < 0 || _menuboxHeight > _subMenuHeight - 50) {
      if (_subMenuHeight - 50 > _menuboxHeight) {
        $('.subMenu .menuBox').css('margin-top', '0px');
        return;
      }

      if (_scroll > 0) {
        $('.subMenu .menuBox').css(
          'margin-top',
          _mt < 0 ? _mt + _scroll + 'px' : 0
        );
      } else {
        $('.subMenu .menuBox').css(
          'margin-top',
          _mt > _subMenuHeight - _menuboxHeight - 70 ?
            _mt + _scroll + 'px' :
            _subMenuHeight - _menuboxHeight - 70 + 'px'
        );
      }
    }
  }

  favMenuScrollEvt(event) {
    event = window.event || event;
    const _scroll = event.wheelDelta ? event.wheelDelta : -(event.detail || 0) * 9,
      _mt = parseInt($('.favMenuBox ul').css('margin-top')),
      _favMenuHeight = $('.favMenuBox').height(),
      _menuboxHeight = $('.favMenuBox ul').height();
    if (_mt < 0 || _menuboxHeight > _favMenuHeight) {
      if (_favMenuHeight > _menuboxHeight) {
        $('.favMenuBox ul').css('margin-top', '0px');
        return;
      }
      let _new_mt = 0
      if (_scroll > 0) {
        _new_mt = _mt < 0 ? _mt + _scroll : 0
        $('.favMenuBox ul').css('margin-top', _new_mt + 'px');
      } else {
        _new_mt = _mt > _favMenuHeight - _menuboxHeight ? _mt + _scroll : _favMenuHeight - _menuboxHeight;
        $('.favMenuBox ul').css('margin-top', _new_mt + 'px');
      }
      _new_mt < 0 ? $('.favMenuBox').addClass('overflow-top') : $('.favMenuBox').removeClass('overflow-top')
      _new_mt + _menuboxHeight > _favMenuHeight ? $('.favMenuBox').addClass('overflow-bottom') : $('.favMenuBox').removeClass('overflow-bottom')
    }
  }

  windowResizeEvt() {
    // let _mainMenuHeight = $('.mainMenu').height(),
    // fix: 18年至今一直都在的报错
    let _menuboxHeight = $('.mainMenu .menuBox').height();
    //   _mainMenuMT = parseInt($('.mainMenu .menuBox').css('margin-top'));
    let _subMenuHeight = $('.subMenu').height(),
      _subMenuboxHeight = $('.subMenu .menuBox').height(),
      _subMenuMT = parseInt($('.subMenu .menuBox').css('margin-top'));
    if (_subMenuMT < 0) {
      $('.subMenu .menuBox').css(
        'margin-top',
        (_subMenuHeight - _menuboxHeight - 70 < 0 ?
          _subMenuHeight - _menuboxHeight - 70 :
          0) + 'px'
      );
    }

    $('header .product').height((document.documentElement.clientHeight || document.body.offsetHeight) - 50);
    let _boxWidth = $('.YnaviHead').width() - (_languageId === 'en' ? 340 : 280)
    $('header .productBox .menuSearchbox, header .productBox .dropMenuBox').css({
      width: (_languageId === 'en' ? 860 : 960) + 'px'
    });
    // 瀑布流布局初始化
    new Masonry(document.querySelector('header .productBox .dropMenuBox'), {
      itemSelector: '.pro-item',
      columnWidth: _languageId === 'en' ? 250 : 200,
      fitWidth: false,
      gutter: _languageId === 'en' ? 20 : 48
    });

  }

  sideMenuAdd(config) {
    if (!config || isNaN(Number(config.level))) return;
    switch (config.level) {
      case 1:
        _naviObj.sideMenu.splice(config.index1 - 1, 0, config.menuObj);
        break;
      case 2:
        _naviObj.sideMenu[config.index1 - 1].childMenu.splice(
          config.index2 - 1,
          0,
          config.menuObj
        );
        break;
      case 3:
        _naviObj.sideMenu[config.index1 - 1].childMenu[
          config.index2 - 1
        ].childMenu.splice(config.index3 - 1, 0, config.menuObj);
        break;
    }
    _sideMenuAdded = false;
    _naviObj.fillSideMenu();
    _naviObj.setSubMenuStatus();
  }

  hidePopAlert() {
    $('.YPopLayer').removeClass('view');
    let hidePopHandle = setTimeout(() => {
      $('.YPopLayer').hide();
      clearTimeout(hidePopHandle);
    }, 3000);
    services.delNgDomainMsg();
    return;
  }

  toReadStatus() {
    const isRead = $(".YnaviMsgLayer input[type='checkbox']").prop('checked');
    $('.YnaviMsgLayer .msgConfirm').prop(
      'class',
      isRead ? 'msgConfirm' : 'msgConfirm disable'
    );
  }

  readedMsg() {
    if ($('.YnaviMsgLayer .msgConfirm').hasClass('disable')) {
      return;
    }
    $('.YnaviMsgLayer').removeClass('view');
    let readHandle = setTimeout(() => {
      $('.YnaviMsgLayer').hide();
      clearTimeout(readHandle);
    }, 3000);
    services.markMsgHaveRead(_msgId);
    return;
  }

  deleteFavMenuEvt(){
    try{
      services.saveFavMenu(_favMenuPages.join(',')).then((_delData) => {
        _isDeleteMenu = false
        if (_delData.code === 0) {
          if (_favMenuPages.length <= 0) {
            services.getFavMenu().then(_data => {
              if (_data.code === 0) {
                _favMenuPages = _data.result ? (isString(_data.result) ? _data.result.split(',') : [..._data.result]) : []
                _naviObj.fillFavMenu()
                _naviObj.searchMenu('')
              }
              else {
                _favMenuPages = [..._defalutFavMenu]
                _naviObj.fillFavMenu()
                _naviObj.searchMenu('')
              }
            })
          }
        }
      });
    }
    catch(_err){
      // console.log(_err)
      _isDeleteMenu = false
    }
  }

  setFavMenuStyle(){
    let _boxHeight = $(".product .favMenuBox").height(),
      _menuHeight = $(".product .favMenuBox ul").height(),
      _mt = parseInt($(".product .favMenuBox ul").css('margin-top'));
    if (_menuHeight > _boxHeight) {
      if (_mt + _menuHeight > _boxHeight) {
        $(".product .favMenuBox").addClass('overflow-bottom')
      }
      else {
        _mt = _boxHeight - _menuHeight
        $(".product .favMenuBox ul").css('margin-top', _mt + 'px');
        $(".product .favMenuBox").removeClass('overflow-bottom')
        if (_mt < 0) $(".product .favMenuBox").removeClass('overflow-top')
      }
    }
    else {
      _mt = 0
      $(".product .favMenuBox ul").css('margin-top', 0);
      $(".product .favMenuBox").removeClass('overflow-top').removeClass('overflow-bottom')
    }
  }

  initEvent() {
    _isChangeName = false;
    // 复制主账号id
    $('body').on('click','header .userContent .userId .icon-copy', this.copyAccountId)
    $('body').on('mouseover', 'header .userContent .userId .icon-copy', this.resetCopyAccountId)
    // 子用户登陆时候，复制主账号id
    $('body').on('click','header .accStatus .adminPin .icon-copy', this.copyAdminAccountId)
    $('body').on('mouseover','header .accStatus .adminPin .icon-copy', this.resetAdminAccountId)
    $('body').on('click', 'header a', this.headerLinkEvt);
    $('body').on('click', '.favMenuBox a', this.handleClickCommonService);
    $('body').on('mouseover', 'header .hasChild', this.headerLinkOverEvt);
    $('body').on('mouseout', 'header .hasChild', this.headerLinkOutEvt);
    $('body').on('mouseleave', 'header .hasChild', this.headerLinkLeaveEvt);
    $('body').on('focus', 'header .topSearchBox input', this.headerSearchInputEvt);
    $('body').on('focus', 'header .topSearchBox button', this.headerSearchInputEvt);
    $('body').on('input', 'header .topSearchBox input', this.headerSearchInputChangeEvt);
    // $('body').on('blur', 'header .topSearchBox input', this.headerSearchInputOutEvt);
    // $('body').on('blur', 'header .topSearchBox button', this.headerSearchInputOutEvt);
    $('body').on('focus', 'header .menuSearchbox input', this.headerSearchInputEvt);
    $('body').on('focus', 'header .menuSearchbox button', this.headerSearchInputEvt);
    $('body').on('blur', 'header .menuSearchbox input', this.headerSearchInputOutEvt);
    $('body').on('blur', 'header .menuSearchbox button', this.headerSearchInputOutEvt);
    $('body').on('input', 'header .menuSearchbox input', this.headerSearchInputChangeEvt);
    $('body').on('submit', 'header .topSearchBox form', this.headerSearchFormEvt);
    $('body').on('click', 'header .product a', this.dropMenuEvt);
    $('body').on('click', 'header .product a[class="btn-product-close"]', this.hideDropMenu);
    $('body').on('click', 'header .product .fav', function(_event){
      if (_isDeleteMenu) return false;
      _isDeleteMenu = true
      _event = window.event || _event;
      if (_event.preventDefault) _event.preventDefault();
      let _link = $(this).parent('a')
      let _page = $(_link).attr('data-page'),
        _url = $(_link).attr('data-url'),
        _name = $(_link).attr('data-name'),
        _icon = $(_link).attr('data-icon');
      let _alertObj = $('.product .favAlert')
      if (_favMenuPages.length >= 20 && _favMenuPages.indexOf(_page) < 0) {
        if ($(_alertObj).html() === '') {
          $(_alertObj).html(getTranslation('最多收藏20个常用服务')).css({ left: ($(_link).offset().left + $(_link).width() + 10 - 140) + 'px', top: ($(_link).offset().top - 50 + $(_link).height()) + 'px'}).fadeIn().delay(2000).fadeOut(()=>{
            $(_alertObj).html('')
          });
        }
        _isDeleteMenu = false
        return false
      }
      let _favLink = $(".product .favmenu ul a[data-page='" + _page + "']").parent('li')
      if (_favMenuPages.indexOf(_page) >= 0) {
        _favMenuPages.splice(_favMenuPages.indexOf(_page),1)
        $(this).removeClass('faved')
        $(_favLink).slideUp('fast', _naviObj.setFavMenuStyle);
        $(".product .favmenu h3 span").html(_favMenuPages.length + '/20')
        setTimeout(() => {
          $(_favLink).remove();
          _naviObj.setFavMenuStyle()
        }, 400)
      }
      else{
        _favMenuPages.push(_page)
        $(this).addClass('faved')
        let fatIcon = _icon
        if (fatIcon && fatIcon.indexOf('\\u') !== -1) {
          try { fatIcon = JSON.parse('["' + fatIcon + '"]')[0] } catch (_) {}
        }
        if (fatIcon && fatIcon.length >= 1 && fatIcon.charAt(0) === 'u') {
          try { fatIcon = JSON.parse('["' + '\\' + fatIcon + '"]')[0] } catch (_) {}
        }
        $('.product .favmenu ul').append(`<li class="favMenuLi favMenuLi${_favMenuPages.length-1}"><a href="${_url}" data-page="${_page}" data-url="${_url}" clstag="consoleNavi|keycount|favMenu|${_url}" target = "_blank"><span class="menuTree"><i class="YnaviMenuIcon"> ${fatIcon} </i>${_name}</span><span class="icons"><i class="YnaviMenuIcon del"></i><i class="YnaviMenuIcon move"></i></span></a></li>`)
        $(".product .favmenu h3 span").html(_favMenuPages.length + '/20')
        _naviObj.setFavMenuStyle()
      }
      _naviObj.deleteFavMenuEvt()
      return false;
    });

    $('body').on('click', 'header .product .favmenu i.del', function(_event){
      if(_isDeleteMenu) return false;
      _isDeleteMenu = true
      _event = window.event || _event;
      if (_event.preventDefault) _event.preventDefault();
      let _link = $(this).parents('a')
      let _page = $(_link).attr('data-page'),
        _url = $(_link).attr('data-url'),
        _name = $(_link).attr('data-name');
      _favMenuPages.forEach((_favPage,_index)=>{
        if (_favPage === _page){
          _favMenuPages.splice(_index,1)
        }
      })
      $('header .product a[data-page='+ _page +'] .fav').removeClass('faved')
      $(this).removeClass('faved')
      $(_link).parent('li').slideUp('fast');
      // $(".product .favmenu ul").height(_favMenu.length * 30)
      $(".product .favmenu h3 span").html(_favMenuPages.length + '/20')
      setTimeout(()=>{
        $(_link).parent('li').remove();
        _naviObj.setFavMenuStyle()
      },400)
      _naviObj.deleteFavMenuEvt()
      return false;
    });
    let _dragObj = null,
      _dragTempObj = null,
      _dragToObj = null,
      _isDrag = false,
      _dragOffset = {
        left:0,
        top:0
      };

    $('body').on('mousedown', 'header .product .favmenu i.move', function(_event){
      _event = window.event || _event;
      if (_event.preventDefault) _event.preventDefault();
      let _x = _event.pageX || _event.clientX,
        _y = _event.pageY || _event.clientY;
      _dragObj = $(this).parents('.favMenuLi');
      $(_dragObj).find('a').addClass('hover');
      _dragTempObj = $(_dragObj).get(0).cloneNode(true);
      $(_dragTempObj).find('a').addClass('hover');
      _dragOffset.left = _x - $(_dragObj).offset().left;
      _dragOffset.top = _y - $(_dragObj).offset().top + $('.product .favmenu ul').offset().top;
      $(_dragTempObj).insertAfter($(_dragObj)).css("opacity",0.2).addClass("temp");
      $(_dragObj).css({"opacity":0.9,position:"absolute","z-index":5,left:0,top:(_y-_dragOffset.top)+"px"}).addClass("drag");
      _isDrag = true;
      return false
    });

    $(window).on('mousemove', function(_event){
      if(!_isDrag)return;
      _event = window.event || _event;
      if (_event.preventDefault) _event.preventDefault();
      var _x = _event.pageX || _event.clientX,
        _y = _event.pageY || _event.clientY,
        _li = $('header .product .favmenu li');
      $(_dragObj).css({left:0,top:(_y-_dragOffset.top)+"px"});
      $(_li).each(function(){
        if($(this).hasClass("temp"))return;
        if($(this).hasClass("drag"))return;
        let _left = $(this).offset().left,
            _top = $(this).offset().top,
            _width = $(this).outerWidth(),
            _height = $(this).outerHeight(),
            _right = _left + _width,
            _bottom = _top + _height;
        if(_y > _top && _y < _bottom) _dragToObj = this;
        try {
          _y < _top + _height/2 ? $(_dragTempObj).insertBefore(_dragToObj) :$(_dragTempObj).insertAfter(_dragToObj);
        }
        catch (e) {}
      })
      return false;
    });

    $(window).on('mouseup', function(_event){
      if(!_isDrag)return;
      _event = window.event || _event;
      if (_event.preventDefault) _event.preventDefault();
      $(_dragTempObj).css({opacity:1}).removeClass("temp");
      $(_dragTempObj).find('a').removeClass('hover');
      $(_dragObj).remove();
      _dragTempObj = _dragObj = null;
      _isDrag = false;
      let _link = $('header .product .favmenu a')
      _favMenuPages = []
      $(_link).each(function(){
        _favMenuPages.push($(this).attr('data-page'))
      })
      services.saveFavMenu(_favMenuPages.join(','));
      return false;
    });

    $('body').on('DOMMouseScroll', '.product .favMenuBox', this.favMenuScrollEvt);
    $('body').on('mousewheel', '.product .favMenuBox', this.favMenuScrollEvt);

    $('body').on('click', 'header .userCenter a', this.userCenterEvt);
    $('body').on('click', 'header .company ul a', this.accountSel);
    $('body').on('click', '.mainMenu .menu1', this.spreadMenu);
    $('body').on('click', '.mainMenu .cur ul a', this.setSubMenu);

    // $('body').on('DOMMouseScroll', '.mainMenu', this.mainMenuScrollEvt);
    // $('body').on('mousewheel', '.mainMenu', this.mainMenuScrollEvt);
    // 移除左侧菜单滚动事件，使用css代替，下面方法会导致window上出现频繁闪动问题
    // $('body').on('DOMMouseScroll', '.subMenu', this.subMenuScrollEvt);
    // $('body').on('mousewheel', '.subMenu', this.subMenuScrollEvt);
    $(window).on('resize', this.windowResizeEvt);

    $('body').on('click', '.subMenu .subMenuToggle', this.toggleSubMenu_n);
    $('body').on('click', '.subMenu ul a', this.subMenuEvt);
    $('body').on('click', this.hideDropMenu);
    $('body').on(
      'click',
      '.YPopLayer .Ybtn_close,.YPopLayer .Ybtn_enter',
      this.hidePopAlert
    );
    $('body').on(
      'click',
      ".YnaviMsgLayer input[type='checkbox']",
      this.toReadStatus
    );
    $('body').on('click', '.YnaviMsgLayer .msgConfirm', this.readedMsg);

    $(window).on('scroll', function(){
      $(".YnaviHead").css('margin-left', (0 - document.documentElement.scrollLeft) + 'px')
      $(".product").css('margin-left', (0 - document.documentElement.scrollLeft) + 'px')
    })

  }
}
