import {
  endsWith,
  filter,
  findIndex,
  startsWith
} from 'lodash-es';
import urlParse from 'url-parse';

const isDev = process.env.NODE_ENV === 'development'; // 当前是开发环境吗
const domain = getDomain(); // 当前域名
const hostName = domain; // 当前域名的别名
const static2Url = '//static2.jdcloud.com'; // static2地址
const postfix = getUrlPostfix(); // 后缀。如invoice-uc-stag.jdcloud.com，后缀为stag
const msie = document.documentMode; // 当前IE版本
// window.isUC = true
const isUc = (() => {
  let href = location.href;
  href = href.replace('-stag', '');
  const subAppIsUC = window.proxy && window.proxy.window && window.proxy.window.isUC
  // 是UC吗
  return (
    window.isUC === true ||
    subAppIsUC ||
    href.indexOf('uc.jdcloud.com') !== -1 ||
    href.indexOf('cost.jdcloud.com') !== -1 ||
    href.indexOf('uc.jcloud.com') !== -1 ||
    href.indexOf('ticket.jdcloud.com') !== -1 ||
    href.indexOf('ticket.jcloud.com') !== -1 ||
    href.indexOf('partner.jdcloud.com') !== -1 ||
    href.indexOf('contract.jdcloud.com') !== -1 ||
    href.indexOf('billing.jdcloud.com') !== -1 ||
    href.indexOf('contract-console.jdcloud.com') !== -1 ||
    href.indexOf('cost-allocation-console.jdcloud.com') !== -1 ||
    href.indexOf('expense-budget-console.jdcloud.com') !== -1
  );
})();
export const currentNamespace = window.__JDCLOUD_NAMESPACE__ || 'default'; // 当前命名空间
/**
 * 获取当前域名
 * @returns {string} 域名
 */
function getDomain() {
  const hostName = location.hostname;
  return hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  );
}

function getUrlPostfix() {
  function getScriptByName(name) {
    const scripts = document.getElementsByTagName('script');
    const script = filter(scripts, iter => {
      if (iter.src) {
        const url = urlParse(iter.src);
        return endsWith(url.pathname, name);
      }
      return false;
    });
    if (script.length > 0) {
      return script[0].src;
    }
    return '';
  }

  function getUrlPostfixImpl(url) {
    const hostname = urlParse(url).hostname;
    let urlPostfix = hostname
      .split('.')[0]
      .split('-')
      .pop();
    const availablePostfix = ['stag', 'ite'];
    const p = findIndex(availablePostfix, postfix =>
      startsWith(urlPostfix, postfix)
    );
    if (p === -1) {
      urlPostfix = '';
      return urlPostfix;
    }
    return '-' + urlPostfix;
  }

  // 检测script标签上的prefix
  const injectorSrc = getScriptByName('Ynavi.js');
  if (injectorSrc) {
    return getUrlPostfixImpl(injectorSrc);
  }

  return getUrlPostfixImpl(location.href);
}

export default {
  isDev,
  hostName,
  domain,
  postfix,
  msie,
  static2Url,
  currentNamespace,
  isUc
};
